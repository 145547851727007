export const calculateTotalCoins = (trades) => {
    let totalCoins = 0;

    trades.forEach((trade) => {
        totalCoins += parseFloat(trade.amount);
    });

    return totalCoins;
};

// export const calculateAvgPrice = (trades) => {
//     const noZeroAvgTrades = trades.filter(
//         (trade) => parseFloat(trade.price_avg) !== 0
//     );

//     const avgTotal = noZeroAvgTrades.reduce(
//         (acc, trade) => acc + parseFloat(trade.price_avg),
//         0
//     );

//     return avgTotal / noZeroAvgTrades.length;
// };

export const calculateAvgPrice = (totalCost, totalCoins) => {
    return parseFloat(totalCost) / parseFloat(totalCoins);
};

// export const calculateAvgPrice = (trades) => {
//     let avgPrice = 0;

//     trades.forEach((trade) => {
//         avgPrice += trade.price;
//     });

//     avgPrice = avgPrice / trades.length;

//     return avgPrice;
// };
