//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            exchanges: {
                bitmart: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                lbank: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                bitforex: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                latoken: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                bitget: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                coinex: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                mexc: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },

                probit: {
                    apiKey: null,
                    apiSecret: null,
                    apiMemo: null,
                },
            },
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        userId() {
            return this.$store.state.internalApi.userId;
        },
    },

    mounted() {
        fetch(`management-keys`)
            .then((response) => response.json())
            .then((data) => {
                const bitmart = data[0];
                this.exchanges.bitmart.apiKey = bitmart.api_key;
                this.exchanges.bitmart.apiSecret = bitmart.api_secret;
                this.exchanges.bitmart.apiMemo = bitmart.api_memo;

                const lbank = data[1];
                this.exchanges.lbank.apiKey = lbank.api_key;
                this.exchanges.lbank.apiSecret = lbank.api_secret;
                this.exchanges.lbank.apiMemo = lbank.api_memo;

                const bitforex = data[2];
                this.exchanges.bitforex.apiKey = bitforex.api_key;
                this.exchanges.bitforex.apiSecret = bitforex.api_secret;
                this.exchanges.bitforex.apiMemo = bitforex.api_memo;

                const latoken = data[3];
                this.exchanges.latoken.apiKey = latoken.api_key;
                this.exchanges.latoken.apiSecret = latoken.api_secret;
                this.exchanges.latoken.apiMemo = latoken.api_memo;

                const bitget = data[4];
                this.exchanges.bitget.apiKey = bitget.api_key;
                this.exchanges.bitget.apiSecret = bitget.api_secret;
                this.exchanges.bitget.apiMemo = bitget.api_memo;

                const coinex = data[5];
                this.exchanges.coinex.apiKey = coinex.api_key;
                this.exchanges.coinex.apiSecret = coinex.api_secret;
                this.exchanges.coinex.apiMemo = coinex.api_memo;

                const mexc = data[6];
                this.exchanges.mexc.apiKey = mexc.api_key;
                this.exchanges.mexc.apiSecret = mexc.api_secret;
                this.exchanges.mexc.apiMemo = mexc.api_memo;

                const probit = data[7];
                this.exchanges.probit.apiKey = probit.api_key;
                this.exchanges.probit.apiSecret = probit.api_secret;
                this.exchanges.probit.apiMemo = probit.api_memo;
            });
    },

    methods: {
        saveKeys() {
            fetch(`management-keys/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    api_data: this.exchanges,
                }),
            }).then((response) => {
                if (response.status === 200) {
                    this.$vToastify.success(`Api keys saved`);
                } else {
                    this.$vToastify.error(
                        `Something went wrong while saving API keys`
                    );
                }
            });
        },
    },
};
