import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { stats } from "./modules/stats";
import { trading } from "./modules/trading";
import { options } from "./modules/options";
import { externalApi } from "./modules/external-api";
import { internalApi } from "./modules/internal-api";
import { chartDrawings } from "./modules/chart-drawings";
import { tradingBuyLimit } from "./modules/trading-buy-limit";
import { tradingBuyLadder } from "./modules/trading-buy-ladder";
import { tradingSellLimit } from "./modules/trading-sell-limit";
import { tradingSellLadder } from "./modules/trading-sell-ladder";

export default new Vuex.Store({
    modules: {
        stats,
        trading,
        options,
        externalApi,
        internalApi,
        chartDrawings,
        tradingBuyLimit,
        tradingBuyLadder,
        tradingSellLimit,
        tradingSellLadder,
    },

    state: {
        currentSymbolTitle:
            localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
        currentSymbolID: localStorage.getItem("currentSymbolID") || "FREE/USDT",

        chart: null,

        freefundsInQuote: 0,
        freeFunds: {},
        fetchingFreeFundsInQuote: {
            value: false,
        },
        fetchingFreeFundsInTradingMarkets: {
            value: false,
        },
        informationLoaded: false,
        priceScale: 10,

        currentQuoteData: {},
        currentCoinData: {},
        currentMarketData: {
            symbol_id: "TEST/TEST",
        },

        resolution: localStorage.getItem("terminalz-resolution") || 240,
    },

    mutations: {
        changeChart(state, payload) {
            state.chart = payload;
        },

        changeCurrentSymbol(state, payload) {
            state.currentSymbolTitle = payload;
        },

        changeCurrentSymbolId(state, payload) {
            state.currentSymbolID = payload;
        },

        changeFreefundsInQuote(state, payload) {
            if (payload.operand == "initial") {
                state.freefundsInQuote = payload.amount;
            }

            if (payload.operand == "sub") {
                state.freefundsInQuote =
                    state.freefundsInQuote - payload.amount;
            }

            if (payload.operand == "add") {
                state.freefundsInQuote =
                    state.freefundsInQuote + payload.amount;
            }
        },

        changeFreefunds(state, payload) {
            state.freeFunds = payload;
        },

        changeFetchingFreeFundsInQuote(state, payload) {
            state.fetchingFreeFundsInQuote = payload;
        },

        changeFetchingFreeFundsInTradingMarkets(state, payload) {
            state.fetchingFreeFundsInTradingMarkets = payload;
        },

        changeCurrentQuote(state, payload) {
            state.currentQuoteData = payload;
        },

        changeCurrentCoin(state, payload) {
            state.currentCoinData = payload;
        },

        changeInformationLoaded(state, payload) {
            state.informationLoaded = payload;
        },

        changeCurrentMarketData(state, payload) {
            state.currentMarketData = payload;
        },

        changePriceScale(state, payload) {
            state.priceScale = payload;
        },

        changeResolution(state, payload) {
            state.resolution = payload;
        },
    },

    actions: {
        changeChartAction({ commit }, chart) {
            commit("changeChart", chart);
        },

        changeFreefundsInQuoteAction({ commit }, freefundsInQuote) {
            commit("changeFreefundsInQuote", freefundsInQuote);
        },

        changeFreefundsAction({ commit }, freeFunds) {
            commit("changeFreefunds", freeFunds);
        },

        changeFetchingFreeFundsInQuoteAction({ commit }, fetching) {
            commit("changeFetchingFreeFundsInQuote", fetching);
        },

        changeFetchingFreeFundsInTradingMarketsAction({ commit }, fetching) {
            commit("changeFetchingFreeFundsInTradingMarkets", fetching);
        },

        changeCurrentQuoteDataAction({ commit }, currentQuoteData) {
            commit("changeCurrentQuote", currentQuoteData);
        },

        changeCurrentCoinDataAction({ commit }, currentCoinData) {
            commit("changeCurrentCoin", currentCoinData);
        },

        changeCurrentCoinPricesAction({ commit }, currentCoinPrice) {
            commit("changeCurrentCoinPrices", currentCoinPrice);
        },

        changeInformationLoadedAction({ commit }, informationLoaded) {
            commit("changeInformationLoaded", informationLoaded);
        },

        changeCurrentMarketDataAction({ commit }, currentMarket) {
            commit("changeCurrentMarketData", currentMarket);
        },

        changePriceScaleAction({ commit }, priceScale) {
            commit("changePriceScale", priceScale);
        },

        changeResolutionAction({ commit }, resolution) {
            commit("changeResolution", resolution);
        },
    },
});
