export const trading = {
    state: () => ({
        activeTradingTab: "buy",
        activeTradingTypeBuyTab: "buyLimit",
        activeTradingTypeSellTab: "sellLimit",
    }),

    mutations: {
        changeActiveTradingTab(state, payload) {
            state.activeTradingTab = payload;
        },

        changeActiveTradingTypeBuyTab(state, payload) {
            state.activeTradingTypeBuyTab = payload;
        },

        changeActiveTradingTypeSellTab(state, payload) {
            state.activeTradingTypeSellTab = payload;
        },
    },

    actions: {
        changeActiveTradingTabAction({ commit }, active) {
            commit("changeActiveTradingTab", active);
        },

        changeActiveTradingTypeBuyTabAction({ commit }, active) {
            commit("changeActiveTradingTypeBuyTab", active);
        },

        changeActiveTradingTypeBuySellAction({ commit }, active) {
            commit("changeActiveTradingTypeSellTab", active);
        },
    },
};
