export const setSymbolForExchange = (exchange) => {
    switch (exchange) {
        case "bitmart":
            localStorage.setItem("currentSymbolTitle", "BTC_USDT");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "lbank":
            localStorage.setItem("currentSymbolTitle", "btc_usdt");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "bitforex":
            localStorage.setItem("currentSymbolTitle", "coin-usdt-btc");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "bitget":
            localStorage.setItem("currentSymbolTitle", "BTCUSDT_SPBL");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "coinex":
            localStorage.setItem("currentSymbolTitle", "BTC_USDT");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "mexc":
            localStorage.setItem("currentSymbolTitle", "BTC_USDT");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
        case "probit":
            localStorage.setItem("currentSymbolTitle", "BTC-USDT");
            localStorage.setItem("currentSymbolID", "BTC/USDT");
            break;
    }
};
