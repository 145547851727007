export const tradingSellLimit = {
    state: () => ({
        limitSellPrice: 0,
        limitSellAmount: 0,
        limitSellSpend: 0,
    }),

    mutations: {
        changeLimitSellPrice(state, payload) {
            state.limitSellPrice = payload;
        },

        changeLimitSellAmount(state, payload) {
            state.limitSellAmount = payload;
        },

        changeLimitSellSpend(state, payload) {
            state.limitSellSpend = payload;
        },
    },

    actions: {
        changeLimitSellPriceAction({ commit }, limitSellPrice) {
            commit("changeLimitSellPrice", limitSellPrice);
        },

        changeLimitSellAmountAction({ commit }, limitSellAmount) {
            commit("changeLimitSellAmount", limitSellAmount);
        },

        changeLimitSellSpendAction({ commit }, limitSellSpend) {
            commit("changeLimitSellSpend", limitSellSpend);
        },
    },
};
