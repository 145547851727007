export const cancelOrder = async (liveMarket, orderId, symbol, orderLine) => {
    let responseData = undefined;

    await fetch(`${liveMarket}/order/cancel`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")
                .content,
        },
        body: JSON.stringify({
            orderId,
            symbol,
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            responseData = data;
            orderLine.remove();
            orderLine = undefined;
        });

    return responseData;
};

export const placeOrder = () => {};
