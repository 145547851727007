import moment from "moment";

export const convertToEpoch = (dateAsString) => {
    return moment(dateAsString, "DD/MM/YYYY HH:mm:ss").unix();
};

export const addUpFeesCost = (trades) => {
    return trades.length == 1
        ? parseFloat(trades[0].fee.cost)
        : trades.reduce((acc, trade) => acc + parseFloat(trade.fee.cost), 0);
};

export const addUpFeesCostPlain = (trades) => {
    // if (trades.length == 1) {
    //     return parseFloat(trades[0].fee_cost);
    // } else {
    //     return trades.reduce(function (acc, trade) {
    //         return acc + parseFloat(trade.fee_cost);
    //     }, 0);
    // }

    if (trades.length == 1) {
        const feeCostFloat = parseFloat(trades[0].fee_cost);
        const feeCurrencyPriceFloat = parseFloat(trades[0].fee_currency_price);

        return isUSD(trades[0].fee_currency)
            ? feeCostFloat
            : feeCostFloat * feeCurrencyPriceFloat;
    } else {
        return trades.reduce(function (acc, trade) {
            const feeCostFloat = parseFloat(trade.fee_cost);
            const feeCurrencyPriceFloat = parseFloat(trade.fee_currency_price);

            if (isNaN(feeCurrencyPriceFloat)) {
                return acc;
            } else {
                return isUSD(trade.fee_currency)
                    ? acc + feeCostFloat
                    : acc + (feeCostFloat * feeCurrencyPriceFloat);
            }
        }, 0);
    }
};

function isUSD(currency) {
    return currency == "USDT" || currency == "USD" || currency == "USDC";
}

export const addUpBuyCost = (trades) => {
    return trades.length == 1
        ? parseFloat(trades[0].cost)
        : trades
            .filter((trade) => trade.side == "buy")
            .reduce((acc, trade) => acc + parseFloat(trade.cost), 0);
};

export const addUpSellCost = (trades) => {
    return trades.length == 1
        ? parseFloat(trades[0].cost)
        : trades
            .filter((trade) => trade.side == "sell")
            .reduce((acc, trade) => acc + parseFloat(trade.cost), 0);
};

export const addUpTotalAmountOfCoinsBought = (trades) => {
    return trades.length == 1
        ? trades[0].amount
        : trades.reduce((acc, trade) => acc + parseFloat(trade.amount), 0);
};

export const getCorrectTrades = (trade, side, from, to) => {
    if (isNaN(from) && isNaN(to)) {
        return trade.side == side;
    }

    if (!isNaN(from) && isNaN(to)) {
        return trade.side == side && trade.timestamp > from;
    }

    if (isNaN(from) && !isNaN(to)) {
        return trade.side == side && trade.timestamp < to;
    }

    if (!isNaN(from) && !isNaN(to)) {
        return (
            trade.side == side && trade.timestamp > from && trade.timestamp < to
        );
    }
};

export const customReducer = (trades, prop) => {
    return trades.length == 1
        ? trades[0][prop.variableName]
        : trades.reduce((acc, trade) => acc + trade[prop.variableName], 0);
};
