//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            currentCoinData: {},
        };
    },

    watch: {
        active_tab() {
            this.$refs[
                this.$store.state.trading.activeTradingTab
            ].$el.isActive = true;
            this.$refs[this.$store.state.trading.activeTradingTab].$el.click();
        },
    },

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        active_tab: {
            get() {
                return this.$store.state.trading.activeTradingTab;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeActiveTradingTabAction",
                    value
                );
            },
        },
    },

    methods: {
        setActiveBuy() {
            this.$store.dispatch("changeActiveTradingTabAction", "buy");
            this.active_tab = "buy";
        },

        setActiveSell() {
            this.$store.dispatch("changeActiveTradingTabAction", "sell")
                ;
            this.active_tab = "sell";
        },
    },
};
