//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import darkUnica from "highcharts/themes/dark-unica";
import {
    addUpBuyCost,
    addUpFeesCostPlain,
    addUpSellCost,
} from "../../../helpers/bec";

darkUnica(Highcharts);
Vue.use(HighchartsVue, {
    highcharts: Highcharts,
});

export default {
    data() {
        return {
            series: [],
            chart: null,
            categories: [],
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    mounted() {
        fetch(`bitmart/chart/getData`)
            .then((response) => response.json())
            .then((data) => {
                const horizontalData = Object.keys(data);
                const verticalData = [];

                Object.entries(data).forEach((dataPoint) => {
                    const trades = this.formatObserver(dataPoint)[1];

                    const buyCost = addUpBuyCost(trades);
                    const sellCost = addUpSellCost(trades);
                    const feeCost = addUpFeesCostPlain(trades);

                    const brutProfit = sellCost - buyCost;
                    const netProfit = brutProfit - feeCost;

                    if (verticalData.length === 0) {
                        verticalData.push(netProfit);
                    } else {
                        const currentTotal =
                            verticalData[verticalData.length - 1];
                        verticalData.push(netProfit + currentTotal);
                    }

                    // console.log("--------------");
                    // console.log("buyCost", buyCost);
                    // console.log("sellCost", sellCost);
                    // console.log("feeCost", feeCost);
                    // console.log("brutProfit", brutProfit);
                    // console.log("netProfit", netProfit);
                    // console.log("--------------");
                });

                this.series = verticalData;
                this.categories = horizontalData;

                console.log("yAxisData", verticalData);
                console.log("xAxisData", horizontalData);

                this.drawChart();
            });
    },

    watch: {},

    methods: {
        formatObserver(data) {
            return JSON.parse(JSON.stringify(data));
        },
        drawChart() {
            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = Highcharts.chart("container", {
                chart: {
                    type: "line",
                },
                legend: {
                    enabled: false,
                },
                title: {
                    text: "Growth Chart",
                },
                series: [
                    {
                        data: this.series,
                    },
                ],
                xAxis: {
                    categories: this.categories,
                    labels: {
                        rotation: -45,
                        style: {
                            fontSize: "13px",
                            fontFamily: "Roboto, sans-serif",
                        },
                    },
                },
                yAxis: {
                    allowDecimals: true,
                    title: {
                        text: "USDT",
                    },
                },
            });
        },
    },
};
