export const externalApi = {
    state: () => ({
        priceBtc: 0,
        priceEth: 0,
    }),

    mutations: {
        changePriceEth(state, payload) {
            state.priceEth = payload;
        },

        changePriceBtc(state, payload) {
            state.priceBtc = payload;
        },
    },

    actions: {
        changePriceEthAction({ commit }, priceEth) {
            commit("changePriceEth", priceEth);
        },

        changePriceBtcAction({ commit }, priceBtc) {
            commit("changePriceBtc", priceBtc);
        },
    },
};
