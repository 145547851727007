export default {
    // "paneProperties.background": "#1b1c1e",
    "paneProperties.backgroundType": "gradient",
    "paneProperties.backgroundGradientStartColor": "#1b1c1e",
    "paneProperties.backgroundGradientEndColor": "#1b1c1e",
    "paneProperties.vertGridProperties.color": "rgba(255, 255, 255, 0.1)",
    "paneProperties.horzGridProperties.color": "rgba(255, 255, 255, 0.1)",
    "scalesProperties.textColor": "#AAA",
    "paneProperties.crossHairProperties.color": "#fff",
    // Regular Candles
    "mainSeriesProperties.candleStyle.upColor": "#37CB95",
    "mainSeriesProperties.candleStyle.downColor": "#F15959",
    "mainSeriesProperties.candleStyle.drawWick": true,
    "mainSeriesProperties.candleStyle.drawBorder": true,
    "mainSeriesProperties.candleStyle.borderColor": "#fff",
    "mainSeriesProperties.candleStyle.borderUpColor": "#37CB95",
    "mainSeriesProperties.candleStyle.borderDownColor": "#F15959",
    "mainSeriesProperties.candleStyle.wickUpColor": "#37CB95",
    "mainSeriesProperties.candleStyle.wickDownColor": "#F15959",
    "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
};
