//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createDate } from "./../../../helpers/dateHelpers";
import { getSideTextColor } from "./../../../helpers/textHelpers";
import { addUpTotalForSpecificSide } from "./../../../helpers/objectHelpers";

export default {
    data() {
        return {};
    },

    computed: {
        currentSymbol() {
            return this.$store.state.currentSymbolTitle;
        },

        base() {
            return this.currentSymbol.split("_")[0];
        },

        quote() {
            return this.currentSymbol.split("_")[1];
        },

        recentTrades() {
            return this.$store.state.internalApi.recentTrades;
        },

        priceScale() {
            return this.$store.state.priceScale;
        },

        totalBuy() {
            return addUpTotalForSpecificSide(
                this.$store.state.internalApi.recentTrades,
                "buy",
                this.$store.state.internalApi.ticker.current
            );
        },

        totalSell() {
            return addUpTotalForSpecificSide(
                this.$store.state.internalApi.recentTrades,
                "sell",
                this.$store.state.internalApi.ticker.current
            );
        },
    },

    methods: {
        numberWithCommas(x) {
            return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
        },

        caluclateCost(price, amount) {
            let totalAmount = 0;

            if (
                this.currentSymbol.includes("_BTC") ||
                this.currentSymbol.includes("coin-btc") ||
                this.currentSymbol.includes("_BTC".toLowerCase())
            ) {
                totalAmount = (price * amount).toFixed(10);
            }

            if (
                this.currentSymbol.includes("_ETH") ||
                this.currentSymbol.includes("coin-eth") ||
                this.currentSymbol.includes("_ETH".toLowerCase())
            ) {
                totalAmount = (price * amount).toFixed(10);
            }

            if (
                this.currentSymbol.includes("_USDT") ||
                this.currentSymbol.includes("_USD") ||
                this.currentSymbol.includes("coin-usdt") ||
                this.currentSymbol.includes("coin-usdd") ||
                this.currentSymbol.includes("_USDT".toLowerCase()) ||
                this.currentSymbol.includes("_USD".toLowerCase()) ||
                this.currentSymbol.includes("SPBL")
            ) {
                totalAmount = (price * amount).toFixed(2);
            }

            if (
                this.currentSymbol.includes("-USDT") ||
                this.currentSymbol.includes("-USDT".toLowerCase())
            ) {
                totalAmount = (price * amount).toFixed(2);
            }

            return totalAmount;
        },

        getSideTextColor,

        createDate,
    },
};
