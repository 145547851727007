//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { buyRules } from "../../../../helpers/rules";

export default {
    props: [
        "ladderBuySellQuantity",
        "ladderBuyStartPrice",
        "ladderBuyEndPrice",
        "ladderBuyAmountOfOrders",
    ],

    data() {
        return {
            items: 2,
        };
    },

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        rulesLadderBuy() {
            return buyRules(
                this.ladderBuySellQuantity,
                this.$store.state.freefundsInQuote
            );
        },
    },
};
