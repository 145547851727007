//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sanitizeHtml from "sanitize-html";

export default {
    data() {
        return {
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
            formData: {
                html: null,
            },
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    methods: {
        submit() {
            const clean = sanitizeHtml(this.formData.html, {
                allowedTags: [
                    "table",
                    "tbody",
                    "td",
                    "tfoot",
                    "th",
                    "thead",
                    "tr",
                ],
            });

            fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/trade/add/html`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.querySelector(
                            "meta[name=csrf-token]"
                        ).content,
                    },
                    body: JSON.stringify({
                        formData: clean,
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    this.$vToastify.success(`Trades saved`);
                });
        },

        clear() {
            this.datetime = html;
        },
    },
};
