//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            userId: 0,
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    mounted() {
        this.$vuetify.theme.dark = true;

        this.fetchEverything();

        const that = this;
        fetch("/user/id")
            .then((response) => response.json())
            .then(function (data) {
                that.userId = data;
                that.$store.dispatch("changeUserIdAction", data);
            });
    },

    methods: {
        fetchEverything() {
            fetch(`${this.liveMarket}/options/fetch`)
                .then((response) => response.json())
                .then((data) => {
                    if (Object.keys(data).length !== 0) {
                        const parsed = JSON.parse(data.options);

                        this.$store.dispatch(
                            "changeOptionsCookieAction",
                            parsed.cookie
                        );
                    }
                })
                .then(() => {
                    fetch(`${this.liveMarket}/options/fees`)
                        .then((response) => response.json())
                        .then((data) => {
                            this.$store.dispatch("changeFeesAction", data);
                        });
                });
        },
    },
};
