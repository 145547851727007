export const tradingBuyLadder = {
    state: () => ({
        ladderBuySellQuantity: 0,
        ladderBuyStartPrice: 0,
        ladderBuyEndPrice: 0,
        ladderBuyAmountOfOrders: 2,
    }),

    mutations: {
        changeLadderBuySellQuantity(state, payload) {
            state.ladderBuySellQuantity = payload;
        },

        changeLadderBuyStartPrice(state, payload) {
            state.ladderBuyStartPrice = payload;
        },

        changeLadderBuyEndPrice(state, payload) {
            state.ladderBuyEndPrice = payload;
        },

        changeLadderBuyAmountOfOrders(state, payload) {
            state.ladderBuyAmountOfOrders = payload;
        },
    },

    actions: {
        changeLadderBuySellQuantityAction({ commit }, ladderBuySellQuantity) {
            commit("changeLadderBuySellQuantity", ladderBuySellQuantity);
        },

        changeLadderBuyStartPriceAction({ commit }, ladderStartPrice) {
            commit("changeLadderBuyStartPrice", ladderStartPrice);
        },

        changeLadderBuyEndPriceAction({ commit }, ladderBuyEndPrice) {
            commit("changeLadderBuyEndPrice", ladderBuyEndPrice);
        },

        changeLadderAmountOfOrdersAction({ commit }, ladderAmountOfOrders) {
            commit("changeLadderAmountOfOrders", ladderAmountOfOrders);
        },
    },
};
