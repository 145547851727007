//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            percentages: [25, 50, 75, 100],
        };
    },

    methods: {
        setSpentPercentage(value) {
            this.$emit("clicked", value);
        },
    },
};
