//
//
//
//
//
//
//
//

export default {
    data() {
        return {};
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    methods: {
        restartSocket() {
            fetch(`${this.liveMarket}/bitmart/websocket/restart`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    restart: true,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    this.$vToastify.success(
                        `Websockets have been succesfully restarted.`
                    );
                });
        },
    },
};
