//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            userId: 0,
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
        };
    },

    components: {},

    mounted() {
        this.$vuetify.theme.dark = true;

        const that = this;
        fetch("/user/id")
            .then((response) => response.json())
            .then(function (data) {
                that.userId = data;
                that.$store.dispatch("changeUserIdAction", data);
            });
    },
};
