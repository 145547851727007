export const options = {
    state: () => ({
        options: [],
        cookie: "",
        fees: [],
    }),

    mutations: {
        changeOptions(state, payload) {
            state.options.push(payload);
        },

        changeOptionsCookie(state, payload) {
            state.cookie = payload;
        },

        changeFees(state, payload) {
            state.fees.push(payload);
        },
    },

    actions: {
        changeOptionsAction({ commit }, options) {
            commit("changeOptions", options);
        },

        changeOptionsCookieAction({ commit }, cookie) {
            commit("changeOptionsCookie", cookie);
        },

        changeFeesAction({ commit }, fees) {
            commit("changeFees", fees);
        },
    },
};
