export const tradingBuyLimit = {
    state: () => ({
        limitBuyPrice: 0,
        limitBuyAmount: 0,
        limitBuySpend: 0,
    }),

    mutations: {
        changeLimitBuyPrice(state, payload) {
            state.limitBuyPrice = payload;
        },

        changeLimitBuyAmount(state, payload) {
            state.limitBuyAmount = payload;
        },

        changeLimitBuySpend(state, payload) {
            state.limitBuySpend = payload;
        },
    },

    actions: {
        changeLimitBuyPriceAction({ commit }, limitBuyPrice) {
            commit("changeLimitBuyPrice", limitBuyPrice);
        },

        changeLimitBuyAmountAction({ commit }, limitBuyAmount) {
            commit("changeLimitBuyAmount", limitBuyAmount);
        },

        changeLimitBuySpendAction({ commit }, limitBuySpend) {
            commit("changeLimitBuySpend", limitBuySpend);
        },
    },
};
