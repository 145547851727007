export const chartDrawings = {
    state: () => ({
        openOrderSellLines: [],
        openOrderBuyLines: [],
        breakEvenPointLine: null,
        breakEvenPrice: 0,
    }),

    mutations: {
        changeOpenOrderSellLines(state, payload) {
            state.openOrderSellLines.push(payload);
        },

        changeOpenOrderBuyLines(state, payload) {
            state.openOrderBuyLines.push(payload);
        },

        changeBreakEvenPointLine(state, payload) {
            state.breakEvenPointLine = payload;
        },

        changeBreakEvenPrice(state, payload) {
            state.breakEvenPrice = payload;
        },
    },

    actions: {
        changeOpenOrderSellLinesAction({ commit }, openOrderSellLine) {
            commit("changeOpenOrderSellLines", openOrderSellLine);
        },

        changeOpenOrderBuyLinesAction({ commit }, openOrderBuyLine) {
            commit("changeOpenOrderBuyLines", openOrderBuyLine);
        },

        changeBreakEvenPointLineAction({ commit }, breakEvenPointLine) {
            commit("changeBreakEvenPointLine", breakEvenPointLine);
        },

        changeBreakEvenPriceAction({ commit }, breakEvenPrice) {
            commit("changeBreakEvenPrice", breakEvenPrice);
        },
    },
};
