import { find } from "lodash";

export const setLoadingState = (store, value, action) => {
    store.dispatch(action, { value });
};

export const generateRef = (length) => {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
};

export const startFundsLoadingState = (store) => {
    setLoadingState(store, true, "changeFetchingFreeFundsInQuoteAction");

    setLoadingState(
        store,
        true,
        "changeFetchingFreeFundsInTradingMarketsAction"
    );
};

export const fundsCalculationAfterOrderAction = (store, funds, quote) => {
    store.dispatch("changeFreefundsInQuoteAction", {
        operand: "initial",
        amount: getQuoteBalance(funds, quote),
    });

    store.dispatch("changeFreefundsAction", funds);

    setLoadingState(store, false, "changeFetchingFreeFundsInQuoteAction");

    setLoadingState(
        store,
        false,
        "changeFetchingFreeFundsInTradingMarketsAction"
    );
};

function getQuoteBalance(funds, quote) {
    // Probit
    if (funds.hasOwnProperty(quote)) {
        return funds[quote].free;
    }

    if (funds.info.hasOwnProperty("data")) {
        if (funds.info.data.hasOwnProperty("wallet")) {
            return funds.info.data.wallet.find((e) => e.id === quote).available;
        } else {
            return funds.info.data[quote].available;
        }
    } else if (funds.info.hasOwnProperty("balances")) {
        // For MEXC
        return funds.info.balances.find((e) => e.asset == quote).free;
    } else {
        return funds.info.find((e) => e.coinName == quote).available;
    }
}
