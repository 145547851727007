import {
    getQuoteObject,
    getCurrentCoinDataFromObject,
    getCurrentQuoteDataFromObject,
} from "../../helpers/objectHelpers";

export const saveFirst = (store, data) => {
    store.dispatch("changeMyTradesAction", data.userTrades);

    store.dispatch("changeOrderBookAction", data.orderBook);

    store.dispatch("changeTickerCurrentAction", data.ohlcv.at(-1)[4]);

    store.dispatch("changeTickerBidAction", data.orderBook.bids?.[0]?.[0]);

    store.dispatch("changeTickerAskAction", data.orderBook.asks?.[0]?.[0]);

    store.dispatch("changeRecentTradesAction", data.recentTrades);

    store.dispatch("changeOpenOrdersAction", data.openOrders);

    store.dispatch("changeBalanceAction", data.balance);

    store.dispatch("changeCurrentMarketDataAction", data.currentMarket);

    store.dispatch(
        "changePriceScaleAction",
        parseFloat(data.currentMarket.price_max_precision)
    );

    const quoteObject = getQuoteObject(
        data.balance,
        store.state.currentSymbolID.split("/")[1]
    );

    store.dispatch("changeFreefundsInQuoteAction", {
        operand: "initial",
        amount: quoteObject === undefined ? 0 : quoteObject[0][1].free,
    });

    store.dispatch("changeMarketsAction", data.markets);

    store.dispatch("changeTradingMarketsAction", data.tradingMarkets);

    store.dispatch("changeFreefundsAction", data.balance);

    store.dispatch(
        "changeCurrentCoinDataAction",
        getCurrentCoinDataFromObject(
            data.balance,
            store.state.currentSymbolTitle
        )
    );

    store.dispatch(
        "changeCurrentQuoteDataAction",
        getCurrentQuoteDataFromObject(
            data.balance,
            store.state.currentSymbolTitle
        )
    );
};

export const saveSecond = (store, data) => {
    store.dispatch("changeAllOpenOrdersAction", data.allOpenOrders);

    store.dispatch("changeAllClosedOrdersAction", data.allClosedOrders);

    store.dispatch("change24hVolAction", data.twentyFourHourVol);
};
