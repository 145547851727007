//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatEuropean } from "../../helpers/currencyFormatter";

export default {
    data() {
        return {
            coins: [],
            coinsAllData: [],
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        fetchingFreeFunds() {
            return this.$store.state.fetchingFreeFundsInQuote.value;
        },

        twentyFourHourVolBase() {
            return this.$store.state.internalApi.twentyFourHourVol[5];
        },

        twentyFourHourVol() {
            return (
                this.$store.state.internalApi.twentyFourHourVol[5] *
                this.$store.state.internalApi.ticker.current
            );
        },

        totalBtc() {
            return 0;
        },

        totalUsd() {
            this.coins = this.$store.state.internalApi.balance.total
                ? Object.entries(this.$store.state.internalApi.balance.total)
                : null;

            setTimeout(() => {
                this.fetchCoinPrices();
            }, 2000);
        },

        freeFunds() {
            return this.$store.state.freefundsInQuote;
        },
    },

    methods: {
        fetchCoinPrices() {
            if (this.coins != null) {
                fetch(`${this.liveMarket}/market/fetch-coin-prices`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.querySelector(
                            "meta[name=csrf-token]"
                        ).content,
                    },
                    body: JSON.stringify(this.coins),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let coinPricesAggregator = [];

                        data.coinPrices.forEach((coinData) => {
                            coinPricesAggregator.push(coinData);
                        });

                        this.$store.dispatch(
                            "changeCurrentCoinPricesAction",
                            coinPricesAggregator
                        );
                    });
            }
        },

        formatEuropean,
    },
};
