//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";

export default {
    data() {
        return {
            right: null,
            height: 750,
            searchParamAll: "",
            searchParamTrading: "",
            rules: [(v) => v.length <= 25 || "Max 25 characters"],
            dialog: false,
            clickMarketData: null,
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "BTC_USDT",
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        cryptoList() {
            return this.markets
                .filter((item) =>
                    item.market_id
                        .toLowerCase()
                        .includes(this.searchParamAll.toLowerCase())
                )
                .sort((a, b) => a.market_id.localeCompare(b.market_id));
        },

        tradingMarkets() {
            return this.trading
                .filter((item) =>
                    item.market
                        .toLowerCase()
                        .includes(this.searchParamTrading.toLowerCase())
                )
                .sort((a, b) => a.market.localeCompare(b.market));
        },

        markets() {
            return this.$store.state.internalApi.markets;
        },

        trading() {
            return this.$store.state.internalApi.tradingMarkets;
        },
    },

    watch: {
        tradingMarkets() {
            Vue.nextTick(() => {
                const refName = document.getElementById(
                    this.$store.state.currentSymbolTitle
                );

                refName?.scrollIntoView({
                    behavior: "smooth",
                });
            });
        },
    },

    methods: {
        showSymbol(item) {
            const symbolMarketId = isNaN(item.market_id)
                ? item.market_id
                : item.market;

            const symbolExists = this.checkIfSymbolExistsInApi(symbolMarketId);

            this.setLastVisited(item);

            if (this.liveMarket == "lbank") {
                if (symbolExists) {
                    this.changeSymbol(
                        symbolMarketId,
                        item.market_info.symbol_id
                    );
                } else {
                    if (confirm("Symbol does not exist") == true) {
                        this.changeSymbol(
                            symbolMarketId,
                            item.market_info.symbol_id
                        );
                    }
                }
            } else {
                this.changeSymbol(symbolMarketId, item.market_info.symbol_id);
            }
        },

        showSymbolCryptoList(item) {
            const symbolMarketId = isNaN(item.market_id)
                ? item.market_id
                : item.market;

            const symbolExists = this.checkIfSymbolExistsInApi(symbolMarketId);

            // this.setLastVisited(item);

            if (this.liveMarket == "lbank") {
                if (symbolExists) {
                    this.changeSymbol(symbolMarketId, item.symbol_id);
                } else {
                    if (confirm("Symbol does not exist") == true) {
                        this.changeSymbol(symbolMarketId, item.symbol_id);
                    }
                }
            } else {
                this.changeSymbol(symbolMarketId, item.symbol_id);
            }
        },

        setLastVisited(item) {
            fetch(`set_last_visited/${this.liveMarket}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify(item),
            })
                .then((response) => response.json())
                .then((data) => { });
        },

        changeSymbol(market_id, symbol_id) {
            this.$store.commit("changeCurrentSymbol", market_id);
            localStorage.setItem("currentSymbolTitle", market_id);

            this.$store.commit("changeCurrentSymbolId", symbol_id);
            localStorage.setItem("currentSymbolID", symbol_id);

            window.location.reload();
        },

        fetchMarkets() {
            fetch(`${this.liveMarket}/markets`)
                .then((response) => response.json())
                .then(
                    (data) =>
                    (this.items = data.map((item) => {
                        return {
                            title: item.market_id,
                            item: item,
                        };
                    }))
                );
        },

        handler(e, passedData) {
            e.preventDefault();
            this.dialog = true;
            this.clickMarketData = passedData;
        },

        setTradingMarket() {
            fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/set-trading-market`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.querySelector(
                            "meta[name=csrf-token]"
                        ).content,
                    },
                    body: JSON.stringify(this.clickMarketData),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    this.dialog = false;
                    this.$vToastify.success(
                        `Market #${this.clickMarketData.market_id} succesfully added as trading market`
                    );
                });
        },

        removeTradingMarket() {
            fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/remove-trading-market`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": document.querySelector(
                            "meta[name=csrf-token]"
                        ).content,
                    },
                    body: JSON.stringify(this.clickMarketData),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    this.dialog = false;
                    if (data != "failed") {
                        this.$vToastify.success(
                            `Market #${this.clickMarketData.market_id} succesfully removed as trading market`
                        );
                    } else {
                        this.$vToastify.error(
                            `Market #${this.clickMarketData.market_id} isn't a trading market yet`
                        );
                    }
                });
        },

        checkIfSymbolExistsInApi(symbol) {
            return this.arrayIncludesInObj(
                this.$store.state.internalApi.pairs,
                "id",
                symbol
            );
        },

        arrayIncludesInObj(arr, key, valueToCheck) {
            return arr.some((value) => value[key] === valueToCheck);
        },

        formatRowStyling(item) {
            const lastVisitedTimestamp = moment(
                item.market_info.last_visited
            ).unix();
            const currentTimestamp = moment().unix();
            const diffInMiliseconds = currentTimestamp - lastVisitedTimestamp;

            if (diffInMiliseconds < 3600) return this.generateBorder("White"); // Less then an hour
            if (diffInMiliseconds < 21600)
                return this.generateBorder("AntiqueWhite"); // Less then an hour
            if (diffInMiliseconds < 86400)
                return this.generateBorder("MediumSpringGreen"); // Less then a day
            if (diffInMiliseconds < 259200)
                return this.generateBorder("SeaGreen"); // Less then 3 days
            if (diffInMiliseconds < 432000)
                return this.generateBorder("Tomato"); // Less then 5 days
            if (diffInMiliseconds < 604800) return this.generateBorder("Red"); // Less then 7 days
        },

        generateBorder(color) {
            return {
                border: color,
                borderRightWidth: "4px",
                borderLeftWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: 0,
                borderStyle: "solid",
            };
        },
    },
};
