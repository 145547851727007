//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setSymbolForExchange } from "../../helpers/exchangeHelpers";

export default {
    data() {
        return {
            showButtons: true,
            select: {
                exchange: this.$store.state.internalApi.liveMarket,
                slug: this.$store.state.internalApi.liveMarket,
            },
            items: [
                { exchange: "Bitmart", slug: "bitmart" },
                { exchange: "Bitget", slug: "bitget" },
                { exchange: "Probit", slug: "probit" },
                // { exchange: "Lbank", slug: "lbank" },
                // { exchange: "Bitforex", slug: "bitforex" },
                // { exchange: "Latoken", slug: "latoken" },
                // { exchange: "CoinEx", slug: "coinex" },
                // { exchange: "Mexc", slug: "mexc" },
            ],
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    mounted() {
        localStorage.getItem("liveMarket") ||
            localStorage.setItem("liveMarket", "bitmart");
        window.addEventListener('resize', this.checkButtonContainerWidth);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.checkButtonContainerWidth);
    },

    methods: {
        setNewExchange(exchange) {
            const parsedExchange = JSON.parse(JSON.stringify(exchange));

            localStorage.removeItem("currentSymbolTitle");
            setSymbolForExchange(parsedExchange.slug);

            localStorage.setItem("liveMarket", parsedExchange.slug);

            window.location.reload();
        },


        checkButtonContainerWidth() {
            this.$nextTick(() => {
                const container = this.$refs.buttonContainer;
                if (container) {
                    const totalButtonWidth = Array.from(container.children).reduce(
                        (total, button) => total + button.offsetWidth + 10, // 10 is the assumed margin
                        0
                    );
                    this.showButtons = container.offsetWidth >= totalButtonWidth;
                }
            });
        },
    },
};
