//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            userId: 0,
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    mounted() {
        this.$vuetify.theme.dark = true;

        this.fetchEverything();

        const that = this;
        fetch("/user/id")
            .then((response) => response.json())
            .then(function (data) {
                that.userId = data;
                that.$store.dispatch("changeUserIdAction", data);
            });
    },

    methods: {
        fetchEverything() {
            fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/fetch-first-contentful-stats`
            )
                .then((response) => response.json())
                .then((data) => {
                    this.$store.dispatch("changeMarketsAction", data.markets);

                    this.$store.dispatch("changeTradesAction", data.userTrades);

                    this.$store.dispatch("changeTickersAction", data.tickers);

                    this.$store.dispatch("changeBalanceAction", data.balance);
                })
                .then(() => {
                    this.$store.dispatch("changeInformationLoadedAction", true);

                    fetch(
                        `${this.liveMarket}/market/${this.currentSymbol}/fetch-others`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            this.$store.dispatch(
                                "changeAllClosedOrdersAction",
                                data.allClosedOrders
                            );
                        });
                });
        },
    },
};
