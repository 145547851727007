//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import percentage from "calculate-percentages";
import {
    generateRef,
    startFundsLoadingState,
    fundsCalculationAfterOrderAction,
} from "../../helpers";
import { date } from "../../../../helpers/dateHelpers";
import { cancelOrder } from "../../../../helpers/orderHelpers";

export default {
    data() {
        return {
            isMarketSell: false,

            sellQuantity: 0,
            limitSellSpend: 0,

            customPercentage: 0,
            sellType: "limit",

            orderLine: undefined,

            clickedPrice: 0,

            rulesCustomePercentage: [
                (value) => (value || "") <= 100 || "Max 100%",
            ],

            placingOrder: false,
            disableButton: false,

            placedOrders: [],
            orderData: null,
        };
    },

    computed: {
        latestBid() {
            const bids = JSON.parse(
                JSON.stringify(this.$store.state.internalApi.orderBook.bids)
            );

            return bids[0][0];
        },

        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        limitSellPrice: {
            get() {
                return this.$store.state.tradingSellLimit.limitSellPrice;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLimitSellPriceAction",
                    value
                );
            },
        },

        limitSellAmount: {
            get() {
                return this.$store.state.tradingSellLimit.limitSellAmount;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLimitSellAmountAction",
                    value
                );
            },
        },

        ladderSellSellQuantity: {
            get() {
                return this.$store.state.tradingSellLadder
                    .ladderSellSellQuantity;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderSellSellQuantityAction",
                    value
                );
            },
        },

        ladderSellStartPrice: {
            get() {
                return this.$store.state.tradingSellLadder.ladderSellStartPrice;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderSellStartPriceAction",
                    value
                );
            },
        },

        ladderSellEndPrice: {
            get() {
                return this.$store.state.tradingSellLadder.ladderSellEndPrice;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderSellEndPriceAction",
                    value
                );
            },
        },

        ladderSellAmountOfOrders: {
            get() {
                return this.$store.state.tradingSellLadder
                    .ladderSellAmountOfOrders;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderAmountOfOrdersAction",
                    value
                );
            },
        },

        active_tab: {
            get() {
                return this.$store.state.activeTradingTypeSellTab;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeActiveTradingTypeSellTabAction",
                    value
                );
            },
        },
    },

    watch: {
        limitSellPrice(value) {
            // First we unsubscirbe from the "mouse_up" event if needed.
            this.unsubPriceWhenClicked();

            if (this.sellType == "market") return;
            console.log(date(), "limitSellPrice changed", value);

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitSellPrice !== 0) {
                console.log(
                    date(),
                    "Creating sell order line from with limitSellPrice watcher"
                );
                this.createSellOrderLine(() => {
                    if (this.orderLine != undefined && this.orderLine) {
                        this.limitSellPrice = this.orderLine.getPrice();
                        this.limitSellSpend =
                            this.orderLine.getPrice() * this.limitSellAmount;
                    }
                });
            }
        },

        limitSellSpend(value) {
            if (this.sellType == "market") return;
            console.log(date(), "limitSellSpend changed", value);

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitSellSpend !== 0) {
                // Only set price if user hasn't chosen price yet
                if (!this.limitSellPrice) this.setLimitSellPrice();

                this.limitSellAmount =
                    this.limitSellSpend / this.limitSellPrice;

                console.log(
                    date(),
                    "Creating sell order line from with limitSellSpend watcher"
                );
                this.createSellOrderLine(() => {
                    if (this.orderLine != undefined && this.orderLine) {
                        this.limitSellPrice = this.orderLine.getPrice();
                        this.limitSellSpend =
                            this.orderLine.getPrice() * this.limitSellAmount;
                    }
                });
            }
        },

        limitSellAmount(value) {
            if (this.sellType == "market") return;

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitSellAmount !== 0) {
                if (this.limitSellAmount !== 0) {
                    // Only set price if user hasn't chosen price yet
                    if (!this.limitSellPrice) {
                        this.setLimitSellPrice();
                    } else {
                        this.limitSellPrice = this.orderLine.getPrice();
                    }
                }

                this.limitSellSpend =
                    this.limitSellPrice * this.limitSellAmount;

                console.log(
                    date(),
                    "Creating sell order line from with limitSellAmount watcher"
                );
                this.createSellOrderLine();
            }
        },

        active_tab() {
            this.$refs[
                this.$store.state.activeTradingTypeSellTab
            ].$el.isActive = true;
            this.$refs[this.$store.state.activeTradingTypeSellTab].$el.click();
        },
    },

    methods: {
        setWindowMarket() {
            this.isMarketSell = true;
            this.sellType = "market";
        },

        setWindowLimit() {
            this.isMarketSell = false;
            this.sellType = "limit";
        },

        setWindowLimitLadder() {
            this.isMarketSell = false;
            this.sellType = "limitLadder";
        },

        setPriceWhenClicked() {
            this.limitSellPrice = this.clickedPrice;
        },

        setSpentPercentage(chosenPercentage) {
            this.customPercentage = chosenPercentage;

            // Only set price if user hasn't chosen price yet
            if (!this.limitSellPrice) this.setLimitSellPrice();

            this.limitSellAmount = percentage
                .of(
                    chosenPercentage,
                    this.$store.state.freeFunds[this.base].free
                )
                .toFixed(10);

            this.createSellOrderLine(() => {
                if (this.orderLine != undefined && this.orderLine) {
                    this.limitSellPrice = this.orderLine.getPrice();
                }
            });
        },

        setLimitSellPrice() {
            // this.limitSellPrice =
            //     this.$store.state.interalApi?.ticker.current;
            this.limitBuyPrice =
                this.$store.state.interalApi?.orderBook?.asks[0];
        },

        setSpendAmount(chosenSpend) {
            this.limitSellSpend = chosenSpend;
        },

        findPrice() {
            this.$store.state.chart
                .chart()
                .crossHairMoved()
                .subscribe(null, ({ time, price }) =>
                    this.setPriceState(price)
                );

            this.$store.state.chart.subscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
        },

        setPriceState(price) {
            this.clickedPrice = price;
            this.orderHasBeenPlaced = false;
        },

        createSellOrderLine(onMove) {
            this.placingOrder = true;
            this.orderLine === undefined || this.orderLine.remove();

            this.orderLine = this.$store.state.chart
                .activeChart()
                .createOrderLine({
                    disableUndo: true,
                });

            const ref = generateRef(12);
            this.orderLine.ref = ref;

            this.orderLine
                .setPrice(this.limitSellPrice)
                .setQuantity(this.limitSellAmount)
                .setText("SELL")
                .setLineColor("#F15959")
                .setBodyFont("12px Roboto, sans-serif")
                .setBodyBorderColor("#F15959")
                .setBodyTextColor("#f5f5f5")
                .setBodyBackgroundColor("#F15959")
                .setModifyTooltip("Edit")
                .setQuantityFont("12px Roboto, sans-serif")
                .setQuantityBackgroundColor("#F15959")
                .setQuantityBorderColor("#F15959")
                .setCancelButtonIconColor("#F15959")
                .setCancelButtonBorderColor("#F15959")
                .setCancelButtonBackgroundColor("#f5f5f5")
                .onModify({}, (e) => { })
                .onMove({}, onMove)
                .onCancel({}, async () => {
                    if (this.placingOrder == false) {
                        const currentClickOrderLine =
                            this.getOrderLineByRef(ref);

                        const data = await cancelOrder(
                            this.liveMarket,
                            currentClickOrderLine.orderLine.orderId,
                            currentClickOrderLine.orderLine.symbol,
                            currentClickOrderLine.orderLine,
                            this.$store
                        );

                        startFundsLoadingState(this.$store);
                        this.fetchFunds();

                        this.formHasBeenSubmitted = false;
                        this.$vToastify.success(
                            `Order #${data.id} removed succesfully`
                        );
                    } else {
                        this.orderLine == undefined || this.orderLine.remove();
                        this.orderLine == undefined ||
                            (this.orderLine = undefined);
                        this.resetForm();
                        this.unsubPriceWhenClicked();
                        this.placingOrder = false;
                    }
                })
                .setLineStyle(2)
                .setExtendLeft(true);
        },

        resetForm() {
            this.limitSellPrice = 0;
            this.limitSellSpend = 0;
            this.limitSellAmount = 0;
            this.customPercentage = 0;
        },

        formatPrice(price, precision = 10) {
            console.log('formatting to precision ' + precision + ' new number ' + Number(price).toFixed(precision));

            return Number(price).toFixed(precision);
        },

        placeOrder() {
            this.disableButton = true;
            this.checkMarketSellConditions();
            this.checkLimitSellConditions();
            this.checkLimitLadderSellConditions();
            if (this.checkIfWouldBecomeMarketOrder() == false) {
                console.log("Overselling, not performing order");
                this.placingOrder = false;
                this.disableButton = false;
                return;
            }

            fetch(`${this.liveMarket}/order/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    symbol: this.$store.state.currentSymbolTitle,
                    type: this.sellType,
                    side: "sell",
                    amount:
                        this.sellType == "market"
                            ? this.sellQuantity
                            : this.limitSellAmount,
                    price:
                        this.sellType == "market" ? null : this.formatPrice(this.limitSellPrice, this.$store.state.currentMarketData.price_max_precision),
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    this.$vToastify.success(
                        `Order #${data.id} created succesfully`
                    );

                    if (this.orderLine != undefined && this.orderLine) {
                        // Set up parameters to cancel order later
                        this.orderLine.orderId = data.id;
                        this.orderLine.symbol = data.symbol;

                        // Setup orders can no longer be moved
                        delete this.orderLine._onMoveCallback;
                        delete this.orderLine._onMoveData;

                        this.placedOrders.push({ orderLine: this.orderLine });
                        this.orderLine = undefined;
                    }

                    this.placingOrder = false;
                    this.disableButton = false;

                    this.$store.state.chart.unsubscribe(
                        "mouse_up",
                        this.setPriceWhenClicked
                    );

                    startFundsLoadingState(this.$store);
                    this.fetchFunds();

                    this.resetForm();
                })
                .catch((err) => {
                    this.disableButton = false;
                    console.log(err);
                    this.$vToastify.error(`${err}`);
                });
        },

        checkMarketSellConditions() {
            if (this.sellType == "market" && this.sellQuantity === 0) {
                console.log("Market order sellQuantity not set");
                this.$vToastify.error("Please enter sell quantity");
                this.placingOrder = false;
                return;
            }
        },

        checkLimitSellConditions() {
            if (
                this.sellType == "limit" &&
                (this.limitSellPrice === 0 || this.limitSellSpend === 0)
            ) {
                console.log("Limit order price or amount not set");
                this.$vToastify.error(
                    "Please make sure you have a price and amount"
                );
                this.placingOrder = false;
                return;
            }
        },

        checkLimitLadderSellConditions() {
            if (
                this.sellType == "limitLadder" &&
                this.ladderSellSellQuantity === 0
            ) {
                console.log("Limit ladder order quantiy is to low");
                this.$vToastify.error(
                    "Please make sure you have a ladder price and amount"
                );
                this.placingOrder = false;
                return;
            }
        },

        checkIfWouldBecomeMarketOrder() {
            if (
                (this.sellType == "limitLadder" || this.sellType == "limit") &&
                this.limitSellPrice < this.latestBid
            ) {
                console.log("You are overselling while limited");

                if (confirm("Overselling while limited, are you sure?")) {
                    console.log("Confirmed overselling");
                    return true;
                }

                console.log("Unconfirmed overselling");
                return false;
            }
        },

        getOrderLineByRef(ref) {
            return this.placedOrders.find((e) => e.orderLine.ref == ref);
        },

        fetchFunds() {
            fetch(`${this.liveMarket}/funding-account?quote=${this.quote}`)
                .then((response) => response.json())
                .then((funds) => {
                    fundsCalculationAfterOrderAction(
                        this.$store,
                        funds,
                        this.quote
                    );
                });
        },

        unsubPriceWhenClicked() {
            this.$store.state.chart.subscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
            this.$store.state.chart.unsubscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
        },
    },
};
