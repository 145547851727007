//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    startFundsLoadingState,
    fundsCalculationAfterOrderAction,
} from "./helpers";
import Datafeed from "../../../datafeed/datafeed.js";
import chartOverrides from "./Chart/overrides/chart";
import { cancelOrder } from "./../../helpers/orderHelpers";
import { widget } from "../../../../public/charting_library";
import { saveFirst, saveSecond } from "./dashboard-state-setters";

function setColor(side) {
    return side == "buy" ? "#37CB95" : "#F15959";
}

// const iconNameToCode = {
//     arrow: {
//         buy: 0xf062,
//         sell: 0xf063,
//     },
//     triangle: {
//         buy: 0xf0d8,
//         sell: 0xf0d7,
//     },
// };

export default {
    name: "Chart",

    props: {
        symbol: {
            default: "Bitfinex:BTC_USDT",
            type: String,
        },
        interval: {
            default: "30",
            type: String,
        },
        libraryPath: {
            default: "../../../../charting_library/",
            type: String,
        },
        chartsStorageUrl: {
            default: "https://saveload.tradingview.com",
            type: String,
        },
        chartsStorageApiVersion: {
            default: "1.1",
            type: String,
        },
        clientId: {
            default: "tradingview.com",
            type: String,
        },
        userId: {
            default: "public_user_id",
            type: String,
        },
        fullscreen: {
            default: false,
            type: Boolean,
        },
        autosize: {
            default: true,
            type: Boolean,
        },
        studiesOverrides: {
            type: Object,
        },
    },

    data() {
        return {
            drawnOrderShapes: [],
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
            fetching: false,
            errorFetching: false,
            fetchingOthers: false,
            showText: false,
        };
    },

    tvWidget: null,

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },
    },

    mounted() {
        this.fetchMarketsList();

        const container = this.$refs.chartContainer;
        const widgetOptions = {
            debug: false,
            symbol: this.symbol,
            datafeed: Datafeed,
            interval: this.$store.state.resolution,
            container: container,
            library_path: this.libraryPath,
            locale: "en",
            disabled_features: [
                "popup_hints",
                "header_symbol_search",
                "show_dialog_on_snapshot_ready"
            ],
            enabled_features: ["side_toolbar_in_fullscreen_mode"],
            popup_hints: false,
            // charts_storage_url: this.chartsStorageUrl,
            // charts_storage_api_version: this.chartsStorageApiVersion,
            client_id: this.clientId,
            user_id: this.userId,
            fullscreen: this.fullscreen,
            autosize: this.autosize,
            theme: this.getParameterByName("theme"),
            custom_css_url: "./themed.css",
            overrides: chartOverrides,
        };

        const tvWidget = new widget(widgetOptions);
        this.tvWidget = tvWidget;

        this.$store.dispatch("changeChartAction", tvWidget);

        this.tvWidget.onChartReady(() => {
            this.tvWidget.activeChart().dataReady(async () => {
                await this.fetchEverything();

                this.drawTrades(this.$store.state.internalApi.myTrades);
                this.drawOpenOrders(this.$store.state.internalApi.openOrders);
                this.drawBreakEvenPoint();
                this.drawTickerInfo(this.$store.state.internalApi.ticker);
            });

            this.tvWidget
                .chart()
                .onIntervalChanged()
                .subscribe(null, (interval, obj) => {
                    console.log("interval changed");
                    this.$store.dispatch("changeResolutionAction", interval);
                    localStorage.setItem("terminalz-resolution", interval);

                    this.drawnOrderShapes.forEach((shape) => {
                        this.tvWidget.activeChart().removeEntity(shape);
                    });

                    this.drawTrades(this.$store.state.internalApi.myTrades);
                    this.drawnOrderShapes = [];
                });

            this.tvWidget
                .chart()
                .onVisibleRangeChanged()
                .subscribe(null, ({ from, to }) => {
                    // console.log("visible range changed");
                    this.drawnOrderShapes.forEach((shape) => {
                        this.tvWidget.activeChart().removeEntity(shape);
                    });
                    this.drawnOrderShapes = [];

                    const currentVisibleTrades =
                        this.$store.state.internalApi.myTrades.filter(
                            (trade) => {
                                return (
                                    trade.timestamp >= from &&
                                    trade.timestamp <= to
                                );
                            }
                        );

                    this.drawTrades(currentVisibleTrades);
                });

            this.tvWidget.headerReady().then(() => {
                var button = this.tvWidget.createButton();
                button.setAttribute('title', 'Toggle Prices');
                button.addEventListener('click', () => {
                    console.log('Toggle prices clicked', this.showText);
                    this.showText = !this.showText;
                    console.log(this.showText);
                });
                button.textContent = 'Toggle prices';
                button.style.color = 'white';

                button.classList.add('toggle-prices-button');
            });

        });
    },

    destroyed() {
        if (this.tvWidget !== null) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
    },

    methods: {
        async fetchEverything() {
            this.fetching = true;
            // console.log("fetching everything");
            return fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/fetch-first-contentful`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data == "api-keys-not-set") {
                        this.$vToastify.error(
                            `API Keys not set, please set API keys via "API" in menu`
                        );
                    } else {
                        saveFirst(this.$store, data);
                        this.fetching = false;
                    }
                })
                .then(() => {
                    this.$store.dispatch("changeInformationLoadedAction", true);

                    // Get a list of available pairs for Lbank
                    if (this.liveMarket === "lbank") {
                        fetch(`${this.liveMarket}/pairs/`)
                            .then((response) => response.json())
                            .then((data) => {
                                this.$store.dispatch("changePairsAction", data);
                            });
                    }

                    // Get all other data
                    this.fetchingOthers = true;
                    fetch(
                        `${this.liveMarket}/market/${this.currentSymbol}/fetch-others`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            saveSecond(this.$store, data);
                            this.fetchingOthers = false;
                        })
                        .catch((err) => {
                            this.fetchingOthers = false;
                        });
                }).catch(err => {
                    this.errorFetching = true;
                });
        },

        async fetchMarketsList() {
            // console.log("fetching market list");
            return fetch(
                `${this.liveMarket}/market/${this.currentSymbol}/fetch-markets-list`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data == "api-keys-not-set") {
                        this.$vToastify.error(
                            `API Keys not set, please set API keys via "API" in menu`
                        );
                    } else {
                        this.$store.dispatch(
                            "changeMarketsAction",
                            data.markets
                        );

                        this.$store.dispatch(
                            "changeTradingMarketsAction",
                            data.tradingMarkets
                        );
                    }
                })
                .then(() => {
                    // Get a list of available pairs for Lbank
                    if (this.liveMarket === "lbank") {
                        fetch(`${this.liveMarket}/pairs/`)
                            .then((response) => response.json())
                            .then((data) => {
                                this.$store.dispatch("changePairsAction", data);
                            });
                    }
                });
        },

        getParameterByName(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null
                ? ""
                : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        drawTrades(trades) {
            const parsedTrades = JSON.parse(JSON.stringify(trades));
            if (!parsedTrades.length > 0) return;

            const chart = this.tvWidget.activeChart();
            const priceRange = chart.getVisiblePriceRange();

            const pricePerPixel = priceRange.diff / 640;

            let lastTimestamp = null;
            let lastYOffset = 0;

            parsedTrades.forEach((trade) => {
                const cost = parseFloat(trade.amount * trade.price_avg).toFixed(2);
                const text = this.showText ? cost : "";

                // Adjust yOffset only if orders are on the same bar
                let yOffset = 0;
                if (trade.timestamp === lastTimestamp) {
                    yOffset = lastYOffset + (5 * pricePerPixel); // Adjust the multiplier as needed
                } else {
                    lastTimestamp = trade.timestamp;
                    lastYOffset = 0;
                }

                const shape = chart.createMultipointShape(
                    [
                        {
                            time: trade.timestamp,
                            price: parseFloat(trade.price_avg),
                        },
                    ],
                    {
                        text: text,
                        lock: true,
                        disableSelection: true,
                        disableSave: true,
                        disableUndo: true,
                        showInObjectsTree: true,
                        shape: trade.side == "buy" ? "arrow_up" : "arrow_down",
                        zOrder: "top",
                        offsetY: yOffset,
                    }
                );

                this.drawnOrderShapes.push(shape);

                // Update lastYOffset for the next trade if it's on the same bar
                if (trade.timestamp === lastTimestamp) {
                    lastYOffset += (10 * pricePerPixel); // Adjust the multiplier as needed
                }
            });
        },

        redrawOrderShapes() {
            this.drawnOrderShapes.forEach((shape) => {
                this.tvWidget.activeChart().removeEntity(shape);
            });

            this.drawnOrderShapes = [];

            this.drawTrades(this.$store.state.internalApi.myTrades);

            // this.userTrades.forEach((trade) => {
            //     const shape = this.drawArrow(trade, this.showText);
            //     this.drawnOrderShapes.push(shape);
            // });
        },

        // drawArrow(trade, showText) {
        //     const cost = parseFloat(trade.amountOfCoins * trade.price_avg).toFixed(2);
        //     const text = showText ? cost : "";

        //     return this.tvWidget.activeChart().createMultipointShape(
        //         [
        //             {
        //                 time: trade.timestamp / 1000,
        //                 price: parseFloat(trade.price_avg),
        //             },
        //         ],
        //         {
        //             text: text,
        //             lock: true,
        //             disableSelection: true,
        //             disableSave: true,
        //             disableUndo: true,
        //             showInObjectsTree: false,
        //             shape: trade.side == "buy" ? "arrow_up" : "arrow_down",
        //             zOrder: "top",
        //         }
        //     );
        // },

        drawOpenOrders(orders) {
            if (orders > 0) return;
            orders.forEach((order) => {
                let orderLine = this.tvWidget.activeChart().createOrderLine({
                    disableUndo: true,
                });

                // console.log(order);

                orderLine
                    .setPrice(order.price)
                    .setQuantity(order.amount)
                    .setText(this.timeSince(order.timestamp) + ' | ' + (order.side == "buy" ? "BUY" : "SELL") + ' | ' + (order.info.notional ? order.info.notional + ' USDT' : (order.amount * order.price).toFixed(2) + ' USDT'))
                    .setLineColor(setColor(order.side))
                    .setBodyFont("12px Roboto, sans-serif")
                    .setBodyBorderColor(setColor(order.side))
                    .setBodyTextColor(
                        order.side == "buy" ? "#000000" : "#f5f5f5"
                    )
                    .setBodyBackgroundColor(setColor(order.side))
                    .setQuantityFont("12px Roboto, sans-serif")
                    .setQuantityBackgroundColor(setColor(order.side))
                    .setQuantityBorderColor(setColor(order.side))
                    .setQuantityTextColor(
                        order.side == "buy" ? "#000000" : "#f5f5f5"
                    )
                    .setCancelButtonIconColor(setColor(order.side))
                    .setCancelButtonBorderColor(setColor(order.side))
                    .setCancelButtonBackgroundColor("#f5f5f5")
                    .setModifyTooltip("Edit")
                    .onModify({}, (e) => {
                        this.$store.dispatch(
                            "changeActiveTradingTabAction",
                            order.side == "buy" ? "buy" : "sell"
                        );
                    })
                    .onCancel({}, async () => {
                        const data = await cancelOrder(
                            this.liveMarket,
                            order.id,
                            "symbol" in order.info
                                ? order.info.symbol
                                : order.symbol,
                            orderLine,
                            this.$store
                        );

                        startFundsLoadingState(this.$store);
                        this.fetchFunds();
                    })
                    .setLineStyle(2)
                    .setExtendLeft(true);

                // Push orderlines into state so we can reference them later on
                if (order.side == "sell") {
                    this.$store.dispatch(
                        "changeOpenOrderSellLinesAction",
                        orderLine
                    );
                } else if (order.side == "buy") {
                    this.$store.dispatch(
                        "changeOpenOrderBuyLinesAction",
                        orderLine
                    );
                }
            });
        },

        drawBreakEvenPoint() {
            let breakEvenPointLineId = this.tvWidget
                .activeChart()
                .createMultipointShape(
                    [
                        {
                            price: this.$store.state.chartDrawings
                                .breakEvenPrice,
                        },
                    ],
                    {
                        shape: "horizontal_line",
                        lock: true,
                        disableSelection: true,
                        disableSave: true,
                        disableUndo: true,
                        showInObjectsTree: false,
                        overrides: {
                            linecolor: "#9F2B68",
                        },
                    }
                );

            this.$store.dispatch(
                "changeBreakEvenPointLineAction",
                breakEvenPointLineId
            );
        },

        drawTickerInfo(ticker) {
            const parsedTicker = JSON.parse(JSON.stringify(ticker));

            // Only draw ask & bid lines if we get actual ticker data
            if (
                Array.isArray(parsedTicker) ||
                parsedTicker?.constructor.name !== "Object"
            ) {
                return;
            }
            // Draw Bid Line
            this.tvWidget.activeChart().createMultipointShape(
                [
                    {
                        price: parseFloat(parsedTicker.bid),
                    },
                ],
                {
                    shape: "horizontal_line",
                    zOrder: "bottom",
                    lock: true,
                    disableSelection: true,
                    overrides: {
                        showPrice: false,
                        showLabel: false,
                        linecolor: "#37CB95",
                        linewidth: 2,
                        horzLabelsAlign: "right",
                    },
                }
            );

            // Draw Ask Line
            this.tvWidget.activeChart().createMultipointShape(
                [
                    {
                        price: parseFloat(parsedTicker.ask),
                    },
                ],
                {
                    shape: "horizontal_line",
                    zOrder: "bottom",
                    lock: true,
                    disableSelection: true,
                    overrides: {
                        showPrice: false,
                        showLabel: false,
                        linecolor: "#F15959",
                        linewidth: 2,
                        horzLabelsAlign: "right",
                    },
                }
            );
        },

        handleErrors(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        },

        fetchFunds() {
            fetch(`${this.liveMarket}/funding-account?quote=${this.quote}`)
                .then((response) => response.json())
                .then((funds) => {
                    fundsCalculationAfterOrderAction(
                        this.$store,
                        funds,
                        this.quote
                    );
                });
        },

        timeSince(dateString) {
            const seconds = Math.floor((new Date() - new Date(dateString)) / 1000);
            const intervals = { 'day': 86400, 'hour': 3600, 'minute': 60, 'second': 1 };

            for (const [label, secondsPerInterval] of Object.entries(intervals)) {
                const count = Math.floor(seconds / secondsPerInterval);
                if (count > 0) {
                    return `${count} ${label}${count === 1 ? '' : 's'} ago`;
                }
            }
            return 'just now';
        },
    },

    watch: {
        showText: {
            handler(newVal, oldVal) {
                if (newVal) {
                    console.log('showtext changed from:', this.showText);
                    console.log('to:', newVal);
                    this.redrawOrderShapes();
                }
            },
        },
    },
};
