export const formatCurrencyQuotePrice = (value, quote) => {
    if (quote == "USD" || quote == "USDT") {
        return value.toFixed(2);
    } else {
        return value.toFixed(10);
    }
};

export const formatCurrencyBasePrice = (value, quote) => {
    if (value === undefined) return;

    return value.toFixed(10);
};

export const formatUsd = (UsdValue, priceScale = 10) => {
    const UsdFloat = parseFloat(UsdValue);

    return UsdFloat.countDecimals() > 2
        ? UsdFloat.toFixed(priceScale)
        : UsdFloat.toFixed(2);
};

export const formatEuropean = (value) => {
    return Number(value).toLocaleString("es-ES", { minimumFractionDigits: 2 });
};

Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

    let str = this.toString();

    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }

    return str.split("-")[1] || 0;
};
