export const date = () => {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var date =
        today.getFullYear() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getDate();
    return date + " " + time;
};

export const createDate = (timestamp) => {
    var date = new Date(timestamp);

    return (
        date.getMonth() +
        1 +
        "-" +
        date.getDate() +
        // "/" +
        // date.getFullYear() +
        " " +
        hours_with_leading_zeros(date) +
        ":" +
        minutes_with_leading_zeros(date) +
        ":" +
        seconds_with_leading_zeros(date)
    );
};

function hours_with_leading_zeros(dt) {
    return (dt.getHours() < 10 ? "0" : "") + dt.getHours();
}

function minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
}

function seconds_with_leading_zeros(dt) {
    return (dt.getSeconds() < 10 ? "0" : "") + dt.getSeconds();
}
