export const headers = [
    {
        text: "Market",
        align: "start",
        sortable: true,
        value: "currentBase",
    },
    {
        text: "Net Profit",
        align: "end",
        sortable: true,
        value: "netProfit",
    },
    {
        text: "Fees Paid",
        align: "end",
        sortable: true,
        value: "feesPaid",
    },
    {
        text: "Invested",
        align: "end",
        sortable: true,
        value: "invested",
    },

    {
        text: "ROI",
        align: "end",
        sortable: true,
        value: "roi",
    },
    // {
    //     text: "Realized Profit",
    //     align: "end",
    //     value: 1,
    // },
    // {
    //     text: "Unrealized Profit",
    //     align: "end",
    //     value: 1,
    // },
];
