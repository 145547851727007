//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            valid: false,
            overlay: false,
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        currentCookie: {
            get() {
                return this.$store.state.options.cookie;
            },

            set(newValue) {
                return this.$store.dispatch(
                    "changeOptionsCookieAction",
                    newValue
                );
            },
        },
    },

    methods: {
        saveOptions() {
            fetch(`${this.liveMarket}/options/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    exchange: 1,
                    cookie: this.currentCookie,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.$vToastify.success(`Options saved`);
                });
        },

        crawlFees() {
            this.overlay = true;

            fetch(`${this.liveMarket}/fees/crawl`)
                .then((response) => response.json())
                .then((data) => {
                    if (data == "loggedout")
                        this.$vToastify.error(`Not logged in, set new cookie`);

                    if (data == "succes")
                        this.$vToastify.success(
                            `Fees have been crawled succesfully`
                        );

                    this.overlay = false;
                    window.location.reload();
                });
        },
    },
};
