//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { headers } from "./headers";
import percentage from "calculate-percentages";
import { findTotalCoinLeftByKey } from "../../../helpers/objectHelpers";

export default {
    data() {
        return {
            headers: headers,
        };
    },

    computed: {
        // base() {
        //     return this.$store.state.currentMarketData.symbol_id.split("/")[0];
        // },

        marketLines() {
            let marketLines = [];

            const tradesKeyedPerCoin = JSON.parse(
                JSON.stringify(this.$store.state.stats.trades)
            );

            if (tradesKeyedPerCoin[0] !== undefined) {
                Object.entries(tradesKeyedPerCoin[0]).forEach((trades) => {
                    const netProfit = this.getNetProfit(trades[1]);
                    const feesPaid = this.getFeespaid(trades[1]);
                    const invested = this.getInvest(trades[1]);
                    const roi = percentage
                        .calculate(netProfit, invested)
                        .toFixed(2);

                    if (!isNaN(netProfit)) {
                        marketLines.push({
                            currentBase: trades[0].split("_")[0],
                            currentQuote: trades[0].split("_")[1],
                            netProfit,
                            feesPaid,
                            invested,
                            roi,
                        });
                    }
                });
            }

            return marketLines;
        },

        totalNetProfit() {
            return this.marketLines
                .reduce((acc, line) => acc + parseFloat(line.netProfit), 0)
                .toFixed(2);
        },

        totalFeesPaidTotal() {
            return this.marketLines
                .reduce((acc, line) => acc + parseFloat(line.feesPaid), 0)
                .toFixed(2);
        },

        totalInvested() {
            return this.marketLines
                .reduce((acc, line) => acc + parseFloat(line.invested), 0)
                .toFixed(2);
        },

        totalRoi() {
            return percentage
                .calculate(this.totalNetProfit, this.totalInvested)
                .toFixed(2);
        },
    },

    watch: {},

    methods: {
        getNetProfit(trades) {
            const symbol = trades[0].market.market_id;
            const base = symbol.split("_")[0];
            const ticker = this.$store.state.stats.tickers[0][symbol];
            const symbolBalance = this.getSymbolBalance(
                this.$store.state.internalApi.balance,
                base
            );
            const residalValue = ticker * symbolBalance;

            console.log(
                symbol,
                "ticker: ",
                ticker,
                "symbolBalance: ",
                symbolBalance,
                "residalValue: ",
                residalValue
            );

            const totalBuyAmount = trades
                .filter((trade) => trade.side == "buy")
                .reduce((acc, trade) => acc + parseFloat(trade.cost), 0);

            const totalSellAmount = trades
                .filter((trade) => trade.side == "sell")
                .reduce((acc, trade) => acc + parseFloat(trade.cost), 0);

            const totalFeesPaid = trades.reduce(
                (acc, trade) => acc + parseFloat(trade.fee_cost),
                0
            );

            console.log("totalBuyAmount: ", totalBuyAmount);
            console.log("totalSellAmount: ", totalSellAmount);
            console.log("totalFeesPaid: ", totalFeesPaid);
            console.log("symbolBalance: ", symbolBalance);

            const totalSellAmountWithResidal = totalSellAmount + residalValue;
            const totalBrutoProfit =
                totalSellAmountWithResidal - totalBuyAmount;
            const netProfit = totalBrutoProfit - totalFeesPaid;

            console.log(
                "totalSellAmountWithResidal: ",
                totalSellAmountWithResidal
            );
            console.log("totalBrutoProfit: ", totalBrutoProfit);
            console.log("netProfit: ", netProfit);

            return netProfit.toFixed(2);
        },

        getFeespaid(trades) {
            return trades
                .reduce((acc, trade) => acc + parseFloat(trade.fee_cost), 0)
                .toFixed(2);
        },

        // getWinRate(trades) {
        //     return 1;
        // },

        getInvest(trades) {
            return trades
                .filter((trade) => trade.side == "buy")
                .reduce((acc, trade) => acc + parseFloat(trade.cost), 0)
                .toFixed(2);
        },

        getRealizedProfit(trades) {
            return 1;
        },

        getUnrealizedProfit(trades) {
            return 1;
        },

        getSymbolBalance(balance, base) {
            // Handling edge cases where Bitmart API returns weird responses
            if (base == "GDT") {
                base = "Gorilla Diamond";
            }

            if (base == "iZOBi") {
                base = "IZOBI";
            }

            try {
                return JSON.parse(
                    JSON.stringify(findTotalCoinLeftByKey(balance, base))
                )[0][1].total;
            } catch (error) {
                console.log("Base could not be found in balance", base);
                console.log(error);
            }
        },

        getBase(trades) {
            const symbol = trades[0].market.market_id;
            return symbol.split("_")[0];
        },

        getQuote(trades) {
            const symbol = trades[0].market.market_id;
            return symbol.split("_")[1];
        },

        formatNetProfitColor(number) {
            const parsedNum = parseFloat(number);

            if (parsedNum > 0) {
                return "tw-text-green-600";
            } else if (parsedNum < 0) {
                return "tw-text-red-500";
            }
        },

        formatFeesPaidColor(number) {
            const parsedNum = parseFloat(number);

            if (parsedNum > 5) {
                return "tw-text-yellow-400";
            }
        },
    },
};
