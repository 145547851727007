//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import percentage from "calculate-percentages";
import { formatUsd } from "./../../../helpers/currencyFormatter";
import { objectNotEmpty } from "./../../../helpers/objectHelpers";
import { formatEuropean } from "./../../../helpers/currencyFormatter";

export default {
    data() {
        return {
            currentAskAmount: 0,
            currentBidAmount: 0,

            dialog: false,
            rowPrice: 0,
        };
    },

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        spread() {
            return (
                this.$store.state.internalApi.ticker.ask -
                this.$store.state.internalApi.ticker.bid
            );
        },

        priceScale() {
            return this.$store.state.priceScale;
        },

        spreadPercentage() {
            return percentage
                .calculate(
                    this.spread,
                    this.$store.state.internalApi.ticker.current
                )
                .toFixed(2);
        },

        orderBookAsksReversed() {
            if (objectNotEmpty(this.$store.state.internalApi.orderBook)) {
                return this.$store.state.internalApi.orderBook.asks.reverse();
            }
        },

        orderBookAsksTotalAmount() {
            return this.$store.state.internalApi.orderBook.asks[
                this.$store.state.internalApi.orderBook.asks.length - 1
            ][2];
        },

        orderBookBids() {
            return this.$store.state.internalApi.orderBook.bids;
        },

        orderBookBidsTotalAmount() {
            return this.$store.state.internalApi.orderBook.bids[
                this.$store.state.internalApi.orderBook.bids.length - 1
            ][2];
        },
    },

    methods: {
        handler(e, data) {
            e.preventDefault();
            this.dialog = true;
            this.rowPrice = data[0];
        },

        drawAlert() {
            this.$store.state.chart.activeChart().dataReady(() => {
                let alertLine = this.$store.state.chart
                    .activeChart()
                    .createOrderLine({
                        disableUndo: true,
                    });

                alertLine
                    .setPrice(this.rowPrice)
                    .setQuantity()
                    .setText("Alert")
                    .setLineColor("#89CFF0")
                    .setBodyFont("bold 10px Roboto, sans-serif")
                    .setBodyBorderColor("#89CFF0")
                    .setBodyBackgroundColor("#89CFF0")
                    .setBodyTextColor("#fff")
                    .setQuantityFont("bold 10px Roboto, sans-serif")
                    .setQuantityBackgroundColor("#89CFF0")
                    .setQuantityBorderColor("#89CFF0")
                    .setCancelButtonIconColor("#89CFF0")
                    .setCancelButtonBorderColor("#89CFF0")
                    .setCancelButtonBackgroundColor("#f5f5f5")
                    .setModifyTooltip("Edit")
                    .onModify({}, (e) => {
                        console.log("Modified: " + JSON.stringify(e));
                    })
                    // .onMove({}, (e) => {
                    //     console.log("Moved handle:" + JSON.stringify(e));
                    // })
                    .onCancel({}, (e) => {
                        alertLine.remove();
                    })
                    .setLineStyle(2)
                    .setExtendLeft(true);

                this.dialog = false;
            });
        },

        formatAskRowCss(ask) {
            return {
                backgroundImage:
                    "linear-gradient(rgba(250, 101, 45, 0.15), rgba(250, 101, 45, 0.15))",
                backgroundSize: `${ask[3]}% 100%`,
                backgroundPosition: "100%",
                textDecoration: this.inArray(
                    this.$store.state.internalApi.openOrders,
                    parseFloat(ask[0])
                )
                    ? "underline"
                    : "",
            };
        },

        formatBidRowCss(bid) {
            return {
                backgroundImage:
                    "linear-gradient(rgba(68, 189, 34, 0.15), rgba(68, 189, 34, 0.15))",
                backgroundSize: `${bid[3]}% 100%`,
                backgroundPosition: "100%",
                textDecoration: this.inArray(
                    this.$store.state.internalApi.openOrders,
                    parseFloat(bid[0])
                )
                    ? "underline"
                    : "",
            };
        },

        setLimitSellPriceFromOrderBook(price) {
            this.$store.dispatch("changeLimitSellPriceAction", price);
        },

        setLimitBuyPriceFromOrderBook(price) {
            this.$store.dispatch("changeLimitBuyPriceAction", price);
        },

        inArray(array, price) {
            return array.some((e) => parseFloat(e.price) === parseFloat(price));
        },

        formatUsd,
        formatEuropean,
    },
};
