import store from "./../js/store/index";
import { makeApiRequestToBackend } from "./helpers.js";
// import {
//     subscribeOnStream,
//     unsubscribeFromStream,
// } from './streaming.js';

const lastBarsCache = new Map();
const CURRENT_SYMBOL =
    localStorage.getItem("currentSymbolTitle") || "FREE_USDT";
const LIVE_MARKET = localStorage.getItem("liveMarket") || "bitmart";

const configurationData = {
    supported_resolutions: [
        "1",
        "2",
        "3",
        "5",
        "10",
        "15",
        "30",
        "60",
        "240",
        "360",
        "720",
        "D",
        "1D",
        "3D",
        "W",
        "1W",
        "2W",
        "1M",
    ],
    exchanges: [
        {
            value: LIVE_MARKET,
            name: LIVE_MARKET,
            desc: LIVE_MARKET,
        },
    ],
    symbols_types: [
        {
            name: "crypto",
            value: "crypto",
        },
    ],
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,
};

export default {
    onReady: (callback) => {
        console.log("[onReady]: Method call");
        setTimeout(() => callback(configurationData));
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
    ) => {
        const symbolItem = await makeApiRequestToBackend(
            `/${LIVE_MARKET}/market/${CURRENT_SYMBOL}/market-info`
        ).catch(function (err) {
            checkBitforex(LIVE_MARKET, err);
        });
        console.log("[resolveSymbol]: Method call", symbolItem.market_id);

        if (!symbolItem) {
            console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
            onResolveErrorCallback("cannot resolve symbol");
            return;
        }

        const symbolInfo = {
            ticker: symbolItem.market_id,
            name: symbolItem.market_id,
            description: symbolItem.market_id,
            type: "crypto",
            session: "24x7",
            timezone: "UTC",
            exchange: LIVE_MARKET,
            minmov: 1,
            pricescale: parseFloat(
                "1" + "0".repeat(symbolItem.price_max_precision)
            ),
            has_intraday: true,
            has_no_volume: false,
            intraday_multipliers: ["5", "15", "30", "60", "150", "240"],
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 1,
            data_status: "streaming",
        };

        console.log("[resolveSymbol]: Symbol resolved", symbolName);
        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
    ) => {
        const { from, to, countBack, firstDataRequest } = periodParams;
        console.log(
            "[getBars]: Method call (symbolinfo, resolution, from, to, limit)",
            symbolInfo,
            resolution,
            from,
            to,
            countBack
        );

        const interval = localStorage.getItem("terminalz-resolution") || 240;

        const intervalMap = {
            5: "5m",
            15: "15m",
            30: "30m",
            60: "1h",
            240: "4h",
        };

        try {
            await makeApiRequestToBackend(
                `/${LIVE_MARKET}/market/${CURRENT_SYMBOL}/bars?from=${from}&to=${to}&resolution=${intervalMap[interval]}&limit=${countBack}`
            )
                .then((candles) => {
                    const bars = candles.map((candle) => {
                        return {
                            time: candle[0],
                            open: candle[1],
                            low: candle[3],
                            high: candle[2],
                            close: candle[4],
                            volume: candle[5],
                        };
                    });

                    console.log(
                        `[getBars]: returned ${bars.length} bar(s)`
                    );

                    if (firstDataRequest && bars.length > 0) {
                        lastBarsCache.set(symbolInfo.ticker, {
                            ...bars[bars.length - 1],
                        });
                    }

                    if (bars.length > 0) {
                        onHistoryCallback(bars, {
                            noData: false,
                        });
                    } else {
                        onHistoryCallback([], {
                            noData: true,
                        });
                    }
                })
                .catch(function (err) {
                    checkBitforex(LIVE_MARKET, err);

                    onHistoryCallback([], { noData: true });

                    console.log("[getBars]: Get error", err);
                    onErrorCallback(err);
                });
        } catch (error) {
            console.log("[getBars]: Get error", error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
    ) => {
        console.log(
            "[subscribeBars]: Method call with subscribeUID:",
            subscribeUID
        );
        // subscribeOnStream(
        //     symbolInfo,
        //     resolution,
        //     onRealtimeCallback,
        //     subscribeUID,
        //     onResetCacheNeededCallback,
        //     lastBarsCache.get(symbolInfo.market_id),
        // );
    },

    unsubscribeBars: (subscriberUID) => {
        console.log(
            "[unsubscribeBars]: Method call with subscriberUID:",
            subscriberUID
        );
        // unsubscribeFromStream(subscriberUID);
    },
};

function checkBitforex(LIVE_MARKET, err) {
    if (LIVE_MARKET == "bitforex") {
        if (confirm("Error:" + err + " // Do you want to reload") == true) {
            window.location.reload();
        }
    }
}
