var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"heigh":_vm.height}},[_c('v-navigation-drawer',{attrs:{"app":"","permanent":"","fixed":"","width":"10%"}},[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":'Search trading',"rules":_vm.rules,"counter":"25","hint":"Search trading markets"},model:{value:(_vm.searchParamTrading),callback:function ($$v) {_vm.searchParamTrading=$$v},expression:"searchParamTrading"}}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-list',{staticClass:"tradingMarkets",attrs:{"dense":"","nav":""}},_vm._l((_vm.tradingMarkets),function(item){return _c('v-list-item',{key:item.title,style:(_vm.formatRowStyling(item)),attrs:{"link":""},on:{"contextmenu":function($event){return _vm.handler($event, item)}}},[_c('v-list-item-content',{attrs:{"id":item.market_info.market_id},on:{"click":function($event){return _vm.showSymbol(item)}}},[_c('v-list-item-title',{staticClass:"text-xl bullet-list-item"},[(item.market_info.api_access == 1)?_c('svg',{attrs:{"height":"17","width":"17"}},[_c('circle',{attrs:{"cx":"5","cy":"10","r":"5","fill":"green"}})]):_vm._e(),_vm._v(" "),(item.market_info.api_access == 0)?_c('svg',{attrs:{"height":"17","width":"17"}},[_c('circle',{attrs:{"cx":"5","cy":"10","r":"5","fill":"red"}})]):_vm._e(),_vm._v(" "),_c('span',{class:_vm.currentSymbol ==
                                item.market_info.market_id
                                ? 'tw-text-yellow-400'
                                : ''},[_vm._v("\n                                "+_vm._s(isNaN(item.market_info.symbol_id)
                                    ? item.market_info.symbol_id
                                    : item.market)+"\n                            ")])])],1)],1)}),1)],1),_vm._v(" "),_c('v-col',[_c('v-divider'),_vm._v(" "),_c('v-text-field',{attrs:{"placeholder":'Search all',"rules":_vm.rules,"counter":"25","hint":"Search all markets"},model:{value:(_vm.searchParamAll),callback:function ($$v) {_vm.searchParamAll=$$v},expression:"searchParamAll"}}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-list',{staticClass:"cryptoList",attrs:{"dense":"","nav":""}},_vm._l((_vm.cryptoList),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"contextmenu":function($event){return _vm.handler($event, item)}}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.showSymbolCryptoList(item)}}},[_c('v-list-item-title',{staticClass:"text-xl bullet-list-item"},[(item.api_access == 1)?_c('svg',{attrs:{"height":"17","width":"17"}},[_c('circle',{attrs:{"cx":"5","cy":"10","r":"5","fill":"green"}})]):_vm._e(),_vm._v(" "),(item.api_access == 0)?_c('svg',{attrs:{"height":"17","width":"17"}},[_c('circle',{attrs:{"cx":"5","cy":"10","r":"5","fill":"red"}})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                                "+_vm._s(isNaN(item.symbol_id)
                                    ? item.symbol_id
                                    : item.market)+"\n                            ")])])],1)],1)}),1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n                Market Quick Actions\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","plain":""},on:{"click":_vm.setTradingMarket}},[_vm._v("Set as trading market")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","plain":""},on:{"click":_vm.removeTradingMarket}},[_vm._v("Remove as trading market")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                    Close\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }