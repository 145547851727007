//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createDate } from "./../../../helpers/dateHelpers";
import { getSideTextColor } from "./../../../helpers/textHelpers";

export default {
    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        allOpenOrders() {
            return this.$store.state.internalApi.allOpenOrders;
        },
    },

    methods: {
        handleClick(item) {
            this.$store.commit("changeCurrentSymbol", item.info.symbol);
            localStorage.setItem("currentSymbolTitle", item.info.symbol);

            this.$store.commit("changeCurrentSymbolId", item.symbol);
            localStorage.setItem("currentSymbolID", item.symbol);

            window.location.reload();
        },

        getSideTextColor,
        createDate,
    },
};
