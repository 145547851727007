export const tradingSellLadder = {
    state: () => ({
        ladderSellSellQuantity: 0,
        ladderSellStartPrice: 0,
        ladderSellEndPrice: 0,
        ladderSellAmountOfOrders: 2,
    }),

    mutations: {
        changeLadderSellSellQuantity(state, payload) {
            state.ladderSellSellQuantity = payload;
        },

        changeLadderSellStartPrice(state, payload) {
            state.ladderSellStartPrice = payload;
        },

        changeLadderSellEndPrice(state, payload) {
            state.ladderSellEndPrice = payload;
        },

        changeLadderSellAmountOfOrders(state, payload) {
            state.ladderSellAmountOfOrders = payload;
        },
    },

    actions: {
        changeLadderSellSellQuantityAction({ commit }, ladderSellSellQuantity) {
            commit("changeLadderSellSellQuantity", ladderSellSellQuantity);
        },

        changeLadderSellStartPriceAction({ commit }, ladderStartPrice) {
            commit("changeLadderSellStartPrice", ladderStartPrice);
        },

        changeLadderSellEndPriceAction({ commit }, ladderSellEndPrice) {
            commit("changeLadderSellEndPrice", ladderSellEndPrice);
        },

        changeLadderAmountOfOrdersAction({ commit }, ladderAmountOfOrders) {
            commit("changeLadderAmountOfOrders", ladderAmountOfOrders);
        },
    },
};
