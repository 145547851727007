//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            apiSet: false,
            userId: 0,
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
            currentSymbolInfo: "",
        };
    },

    mounted() {
        this.$vuetify.theme.dark = true;

        // External
        this.fetchBtcPrice();
        this.fetchEthPrice();

        fetch(`management-keys`)
            .then((response) => response.json())
            .then((data) => {
                const bitmart = data[0];
                if (
                    bitmart.api_key === null &&
                    bitmart.api_secret === null &&
                    bitmart.api_memo === null
                ) {
                    window.location.href = "/api";
                }
            });

        const that = this;
        fetch("/user/id")
            .then((response) => response.json())
            .then(function (data) {
                that.userId = data;
                that.$store.dispatch("changeUserIdAction", data);
            });
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    methods: {
        fetchBtcPrice() {
            fetch("https://api.coinbase.com/v2/prices/spot?currency=USD")
                .then((response) => response.json())
                .then((data) => {
                    this.$store.dispatch(
                        "changePriceBtcAction",
                        data.data.amount
                    );
                });
        },

        fetchEthPrice() {
            fetch("https://api.coinbase.com/v2/prices/ETH-USD/spot")
                .then((response) => response.json())
                .then((data) => {
                    this.$store.dispatch(
                        "changePriceEthAction",
                        data.data.amount
                    );
                });
        },
    },
};
