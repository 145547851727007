export const buyRules = (buyQuantity, freeFundsInQuote) => {
    const rules = [];

    if (buyQuantity) {
        const rule = (v) => v <= freeFundsInQuote || `Insufficient funds`;

        rules.push(rule);
    }

    return rules;
};

export const sellRules = (buyQuantity, freeFundsInQuote) => {
    const rules = [];

    if (buyQuantity) {
        const rule = (v) => v <= freeFundsInQuote || `Insufficient funds`;

        rules.push(rule);
    }

    return rules;
};
