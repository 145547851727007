//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { buyRules } from "../../../../helpers/rules";

export default {
    props: ["limitBuyPrice", "limitBuySpend", "limitBuyAmount"],

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        rulesLimitBuy() {
            return buyRules(
                this.limitBuyAmount,
                this.$store.state.freefundsInQuote
            );
        },
    },
};
