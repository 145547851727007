export const stats = {
    state: () => ({
        trades: [],
        tickers: [],
        totalProfit: 0,
    }),

    mutations: {
        changeTrades(state, payload) {
            state.trades.push(payload);
        },

        changeTickers(state, payload) {
            state.tickers.push(payload);
        },

        changeTotalProfit(state, payload) {
            state.totalProfit + payload;
        },
    },

    actions: {
        changeTradesAction({ commit }, trades) {
            commit("changeTrades", trades);
        },

        changeTickersAction({ commit }, tickers) {
            commit("changeTickers", tickers);
        },

        changeTotalProfitAction({ commit }, netProfit) {
            commit("changeTotalProfit", netProfit);
        },
    },
};
