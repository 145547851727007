//
//
//
//
//
//
//
//
//
//
//
//
//

import { sellRules } from "../../../../helpers/rules";

export default {
    props: ["sellQuantity"],

    computed: {
        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        rulesMarketSell() {
            return sellRules(
                this.sellQuantity,
                this.$store.state.freefundsInQuote
            );
        },
    },
};
