//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import percentage from "calculate-percentages";
import {
    generateRef,
    startFundsLoadingState,
    fundsCalculationAfterOrderAction,
} from "../../helpers";
import { date } from "../../../../helpers/dateHelpers";
import { cancelOrder } from "../../../../helpers/orderHelpers";

export default {
    data() {
        return {
            isMarketBuy: false,

            buyQuantity: 0,

            customPercentage: 0,
            buyType: "limit",

            orderLine: undefined,

            clickedPrice: 0,

            rulesCustomePercentage: [
                (value) => (value || "") <= 100 || "Max 100%",
            ],

            placingOrder: false,
            disableButton: false,

            placedOrders: [],
            orderData: null,

            tvChart: null,
        };
    },

    computed: {
        latestAsk() {
            const asks = JSON.parse(
                JSON.stringify(this.$store.state.internalApi.orderBook.asks)
            );

            return asks[asks.length - 1][0];
        },

        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        limitBuyPrice: {
            get() {
                return this.$store.state.tradingBuyLimit.limitBuyPrice;
            },
            set(value) {
                return this.$store.dispatch("changeLimitBuyPriceAction", value);
            },
        },

        limitBuyAmount: {
            get() {
                return this.$store.state.tradingBuyLimit.limitBuyAmount;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLimitBuyAmountAction",
                    value
                );
            },
        },

        limitBuySpend: {
            get() {
                return this.$store.state.tradingBuyLimit.limitBuySpend;
            },
            set(value) {
                return this.$store.dispatch("changeLimitBuySpendAction", value);
            },
        },

        ladderBuySellQuantity: {
            get() {
                return this.$store.state.tradingBuyLadder.ladderBuySellQuantity;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderBuySellQuantityAction",
                    value
                );
            },
        },

        ladderBuyStartPrice: {
            get() {
                return this.$store.state.tradingBuyLadder.ladderBuyStartPrice;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderBuyStartPriceAction",
                    value
                );
            },
        },

        ladderBuyEndPrice: {
            get() {
                return this.$store.state.tradingBuyLadder.ladderBuyEndPrice;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderBuyEndPriceAction",
                    value
                );
            },
        },

        ladderBuyAmountOfOrders: {
            get() {
                return this.$store.state.tradingBuyLadder
                    .ladderBuyAmountOfOrders;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeLadderAmountOfOrdersAction",
                    value
                );
            },
        },

        active_tab: {
            get() {
                return this.$store.state.activeTradingTypeBuyTab;
            },
            set(value) {
                return this.$store.dispatch(
                    "changeActiveTradingTypeBuyTabAction",
                    value
                );
            },
        },
    },

    watch: {
        limitBuyPrice(value) {
            // First we unsubscribe from the "mouse_up" event if needed.
            this.unsubPriceWhenClicked();

            if (this.buyType == "market") return;
            console.log(date(), "limitBuyPrice changed", value);

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitBuyPrice !== 0) {
                console.log(
                    date(),
                    "Creating buy order line from with limitBuyPrice watcher"
                );
                this.createBuyOrderLine(() => {
                    if (this.orderLine != undefined && this.orderLine) {
                        this.limitBuyPrice = this.orderLine.getPrice();
                        this.limitBuySpend =
                            this.orderLine.getPrice() * this.limitBuyAmount;
                    }
                });
            }
        },

        limitBuySpend(value) {
            if (this.buyType == "market") return;
            console.log(date(), "limitBuySpend changed", value);

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitBuySpend !== 0) {
                // Only set price if user hasn't chosen price yet
                if (!this.limitBuyPrice) {
                    // this.limitBuyPrice =
                    //     this.$store.state.interalApi?.ticker.current;
                    this.limitBuyPrice =
                        this.$store.state.interalApi?.orderBook?.bids[0];
                }

                this.limitBuyAmount = this.limitBuySpend / this.limitBuyPrice;

                console.log(
                    date(),
                    "Creating buy order line from with limitBuySpend watcher"
                );
                this.createBuyOrderLine(() => {
                    if (this.orderLine != undefined && this.orderLine) {
                        this.limitBuyPrice = this.orderLine.getPrice();
                        this.limitBuySpend =
                            this.orderLine.getPrice() * this.limitBuyAmount;
                    }
                });
            }
        },

        limitBuyAmount(value) {
            if (this.buyType == "market") return;

            // Make sure we don't draw an orderline for a 0 price
            if (this.limitBuyAmount !== 0) {
                if (this.limitBuyAmount !== 0) {
                    // Only set price if user hasn't chosen price yet
                    if (!this.limitBuyPrice) {
                        // this.limitBuyPrice =
                        //     this.$store.state.interalApi?.ticker.current;
                        this.limitBuyPrice =
                            this.$store.state.interalApi?.orderBook?.bids[0];
                    } else {
                        this.limitBuyPrice = this.orderLine.getPrice();
                    }
                }

                this.limitBuySpend = this.limitBuyPrice * this.limitBuyAmount;

                console.log(
                    date(),
                    "Creating buy order line from with limitBuyAmount watcher"
                );
                this.createBuyOrderLine();
            }
        },

        active_tab() {
            this.$refs[
                this.$store.state.activeTradingTypeBuyTab
            ].$el.isActive = true;
            this.$refs[this.$store.state.activeTradingTypeBuyTab].$el.click();
        },
    },

    methods: {
        setWindowMarket() {
            this.isMarketBuy = true;
            this.buyType = "market";
        },

        setWindowLimit() {
            this.isMarketBuy = false;
            this.buyType = "limit";
        },

        setWindowLimitLadder() {
            this.isMarketBuy = false;
            this.buyType = "limitLadder";
        },

        setPriceWhenClicked() {
            this.limitBuyPrice = this.clickedPrice;
        },

        setSpentPercentage(chosenPercentage) {
            this.customPercentage = chosenPercentage;

            // Only set price if user hasn't chosen price yet
            if (!this.limitBuyPrice) {
                // this.limitBuyPrice =
                //     this.$store.state.interalApi?.ticker.current;
                this.limitBuyPrice =
                    this.$store.state.interalApi?.orderBook?.bids[0];
            }

            this.limitBuySpend = percentage
                .of(
                    chosenPercentage,
                    this.$store.state.freeFunds[this.quote].free
                )
                .toFixed(10);

            this.createBuyOrderLine(() => {
                if (this.orderLine != undefined && this.orderLine) {
                    this.limitBuyPrice = this.orderLine.getPrice();
                }
            });
        },

        setSpendAmount(chosenSpend) {
            this.limitBuySpend = chosenSpend;
        },

        findPrice() {
            this.$store.state.chart
                .chart()
                .crossHairMoved()
                .subscribe(null, ({ time, price }) =>
                    this.setPriceState(price)
                );

            this.$store.state.chart.subscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
        },

        setPriceState(price) {
            this.clickedPrice = price;
            this.orderHasBeenPlaced = false;
        },

        createBuyOrderLine(onMove) {
            this.placingOrder = true;
            this.orderLine === undefined || this.orderLine.remove();

            this.orderLine = this.$store.state.chart
                .activeChart()
                .createOrderLine({
                    disableUndo: true,
                });

            const ref = generateRef(12);
            this.orderLine.ref = ref;

            this.orderLine
                .setPrice(this.limitBuyPrice)
                .setQuantity(this.limitBuyAmount)
                .setText("BUY")
                .setLineColor("#37CB95")
                .setBodyFont("12px Roboto, sans-serif")
                .setBodyBorderColor("#37CB95")
                .setBodyTextColor("#000000")
                .setBodyBackgroundColor("#37CB95")
                .setQuantityFont("12px Roboto, sans-serif")
                .setQuantityBackgroundColor("#37CB95")
                .setQuantityBorderColor("#37CB95")
                .setQuantityTextColor("#000000")
                .setCancelButtonIconColor("#37CB95")
                .setCancelButtonBorderColor("#37CB95")
                .setCancelButtonBackgroundColor("#f5f5f5")
                .setModifyTooltip("Edit")
                .onModify({}, (e) => { })
                .onMove({}, onMove)
                .onCancel({}, async () => {
                    if (this.placingOrder == false) {
                        const currentClickOrderLine =
                            this.getOrderLineByRef(ref);

                        const data = await cancelOrder(
                            this.liveMarket,
                            currentClickOrderLine.orderLine.orderId,
                            currentClickOrderLine.orderLine.symbol,
                            currentClickOrderLine.orderLine,
                            this.$store
                        );

                        startFundsLoadingState(this.$store);
                        this.fetchFunds();

                        this.formHasBeenSubmitted = false;
                        this.$vToastify.success(
                            `Order #${data.id} removed succesfully`
                        );
                    } else {
                        this.orderLine == undefined || this.orderLine.remove();
                        this.orderLine == undefined ||
                            (this.orderLine = undefined);
                        this.resetForm();
                        this.unsubPriceWhenClicked();
                        this.placingOrder = false;
                    }
                })
                .setLineStyle(2)
                .setExtendLeft(true);
        },

        resetForm() {
            this.limitBuyPrice = 0;
            this.limitBuySpend = 0;
            this.limitBuyAmount = 0;
            this.customPercentage = 0;
        },

        formatPrice(price, precision) {
            console.log('formatting to precision ' + precision + ' new number ' + Number(price).toFixed(precision));

            return Number(price).toFixed(precision);
        },

        placeOrder() {
            this.disableButton = true;
            this.checkMarketBuyConditions();
            this.checkLimitBuyConditions();
            this.checkLimitLadderBuyConditions();
            if (this.checkIfWouldBecomeMarketOrder() == false) {
                console.log("Overbuying, not performing order");
                this.placingOrder = false;
                this.disableButton = false;
                return;
            }

            fetch(`${this.liveMarket}/order/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    symbol: this.$store.state.currentSymbolTitle,
                    type: this.buyType,
                    side: "buy",
                    amount:
                        this.buyType == "market"
                            ? this.buyQuantity
                            : this.limitBuyAmount,
                    price: this.buyType == "market" ? null : this.formatPrice(this.limitBuyPrice, this.$store.state.currentMarketData.price_max_precision),
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    this.$vToastify.success(
                        `Order #${data.id} created succesfully`
                    );

                    if (this.orderLine != undefined && this.orderLine) {
                        // Set up parameters to cancel order later
                        this.orderLine.orderId = data.id;
                        this.orderLine.symbol = data.symbol;

                        // Setup orders can no longer be moved
                        delete this.orderLine._onMoveCallback;
                        delete this.orderLine._onMoveData;

                        this.placedOrders.push({ orderLine: this.orderLine });
                        this.orderLine = undefined;
                    }

                    this.placingOrder = false;
                    this.disableButton = false;

                    this.$store.state.chart.unsubscribe(
                        "mouse_up",
                        this.setPriceWhenClicked
                    );

                    startFundsLoadingState(this.$store);
                    this.fetchFunds();

                    this.resetForm();
                })
                .catch((err) => {
                    this.disableButton = false;
                    console.log(err);
                    this.$vToastify.error(`${err}`);
                });
        },

        checkMarketBuyConditions() {
            if (this.buyType == "market" && this.buyQuantity === 0) {
                console.log("Market order buyQuantity not set");
                this.$vToastify.error("Please enter buy quantity");
                this.placingOrder = false;
                return;
            }
        },

        checkLimitBuyConditions() {
            if (
                this.buyType == "limit" &&
                (this.limitBuyPrice === 0 || this.limitBuySpend === 0)
            ) {
                console.log("Limit order price or amount not set");
                this.$vToastify.error(
                    "Please make sure you have a price and amount"
                );
                this.placingOrder = false;
                return;
            }
        },

        checkLimitLadderBuyConditions() {
            if (
                this.buyType == "limitLadder" &&
                this.ladderBuySellQuantity === 0
            ) {
                console.log("Limit ladder order quantiy is to low");
                this.$vToastify.error(
                    "Please make sure you have a ladder price and amount"
                );
                this.placingOrder = false;
                return;
            }
        },

        checkIfWouldBecomeMarketOrder() {
            if (
                (this.buyType == "limitLadder" || this.buyType == "limit") &&
                this.limitBuyPrice > this.latestAsk
            ) {
                console.log("You are overbuying while limited");

                if (confirm("Overbuying while limited, are you sure?")) {
                    console.log("Confirmed overbuying");
                    return true;
                }

                console.log("Unconfirmed overbuying");
                return false;
            }
        },

        getOrderLineByRef(ref) {
            return this.placedOrders.find((e) => e.orderLine.ref == ref);
        },

        fetchFunds() {
            fetch(`${this.liveMarket}/funding-account?quote=${this.quote}`)
                .then((response) => response.json())
                .then((funds) => {
                    fundsCalculationAfterOrderAction(
                        this.$store,
                        funds,
                        this.quote
                    );
                });
        },

        unsubPriceWhenClicked() {
            this.$store.state.chart.subscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
            this.$store.state.chart.unsubscribe(
                "mouse_up",
                this.setPriceWhenClicked
            );
        },
    },
};
