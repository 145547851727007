//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            currentSymbol:
                localStorage.getItem("currentSymbolTitle") || "FREE_USDT",
            formData: {
                datetime: null,
                price: null,
                amount: null,
                cost: null,
                feeRate: null,
                fee: null,
                direction: null,
                directionItems: ["buy", "sell"],
                feeCurrency: null,
                feeCurrencyItems: ["USDT", "BTC", "ETH"],
                takerOrMaker: null,
                takerOrMakerItems: ["taker", "maker"],
            },
        };
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    methods: {
        submit() {
            fetch(`${this.liveMarket}/market/${this.currentSymbol}/trade/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name=csrf-token]"
                    ).content,
                },
                body: JSON.stringify({
                    formData: this.formData,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.$vToastify.success(`Trade saved`);
                });
        },

        clear() {
            this.datetime = null;
            this.price = null;
            this.amount = null;
            this.cost = null;
            this.fee = null;
            this.direction = null;
            this.feeCurrency = null;
            this.takerOrMaker = null;
        },
    },
};
