var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticStyle:{"height":"47vh"},attrs:{"column":""}},[_c('v-card',{attrs:{"tile":""}},[_c('div',{attrs:{"id":"orderbook-asks"}},[_c('v-simple-table',{attrs:{"dark":"","fixed-header":"","id":"order-book-ask"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-right"},[_vm._v("Price ("+_vm._s(_vm.quote)+")")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v("Amount ("+_vm._s(_vm.base)+")")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v("Total ("+_vm._s(_vm.base)+")")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v("Total ("+_vm._s(_vm.quote)+")")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.orderBookAsksReversed),function(ask,index){return _c('tr',{key:index,style:(_vm.formatAskRowCss(ask)),on:{"contextmenu":function($event){return _vm.handler($event, ask)}}},[_c('td',{staticClass:"\n                                    text-right\n                                    tw-w-1/3\n                                    tw-text-red-500\n                                    tw-cursor-pointer\n                                ",on:{"click":function($event){_vm.setLimitSellPriceFromOrderBook(
                                        _vm.formatUsd(ask[0], _vm.priceScale)
                                    )}}},[_vm._v("\n                                "+_vm._s(_vm.formatUsd(ask[0], _vm.priceScale))+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(ask[1])+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(_vm.formatEuropean(ask[2]))+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(_vm.formatEuropean(
                                        ask[2] *
                                            _vm.$store.state.internalApi.ticker
                                                .current
                                    ))+"\n                            ")])])}),0)])],1),_vm._v(" "),_c('div',{attrs:{"id":"orderbook-spread"}},[_c('v-card',[_c('div',{staticClass:"tw-flex justify-space-around tw-py-1"},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"\n                                    tw-text-gray-400\n                                    text-right\n                                    tw-text-xs\n                                "},[_vm._v("Last price")]),_vm._v(" "),_c('span',{staticClass:"tw-text-xs text-right"},[_vm._v(_vm._s(_vm.$store.state.internalApi.ticker.current !==
                                undefined
                                    ? _vm.$store.state.internalApi.ticker.current.toFixed(
                                          _vm.priceScale
                                      )
                                    : 0))])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"\n                                    tw-text-gray-400\n                                    text-right\n                                    tw-text-xs\n                                "},[_vm._v("Spread")]),_vm._v(" "),_c('span',{staticClass:"tw-text-xs text-right"},[_vm._v(_vm._s(_vm.spread.toFixed(_vm.priceScale)))])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"\n                                    tw-text-gray-400\n                                    text-right\n                                    tw-text-xs\n                                "},[_vm._v("Spread %")]),_vm._v(" "),_c('span',{staticClass:"tw-text-xs text-right"},[_vm._v(_vm._s(_vm.spreadPercentage)+"%")])])])])],1),_vm._v(" "),_c('div',{attrs:{"id":"orderbook-bids"}},[_c('v-simple-table',{attrs:{"dark":"","id":"order-book-bid"}},[_c('tbody',_vm._l((_vm.orderBookBids),function(bid,index){return _c('tr',{key:index,style:(_vm.formatBidRowCss(bid)),on:{"contextmenu":function($event){return _vm.handler($event, bid)}}},[_c('td',{staticClass:"\n                                    text-right\n                                    tw-w-1/3\n                                    tw-text-green-600\n                                    tw-cursor-pointer\n                                ",on:{"click":function($event){_vm.setLimitBuyPriceFromOrderBook(
                                        _vm.formatUsd(bid[0], _vm.priceScale)
                                    )}}},[_vm._v("\n                                "+_vm._s(_vm.formatUsd(bid[0], _vm.priceScale))+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(bid[1])+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(_vm.formatEuropean(bid[2]))+"\n                            ")]),_vm._v(" "),_c('td',{staticClass:"text-right tw-w-1/3"},[_vm._v("\n                                "+_vm._s(_vm.formatEuropean(
                                        bid[2] *
                                            _vm.$store.state.internalApi.ticker
                                                .current
                                    ))+"\n                            ")])])}),0)])],1)])],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n                Orderbook Quick Actions\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","plain":""}},[_vm._v("Create buy order ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","plain":""}},[_vm._v("Create sell order\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"grey","plain":""},on:{"click":_vm.drawAlert}},[_vm._v("Set alert\n            ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                    Close\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }