//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import datetime from "vuejs-datetimepicker";
import percentage from "calculate-percentages";
import {
    addUpBuyCost,
    addUpSellCost,
    convertToEpoch,
    getCorrectTrades,
    addUpFeesCostPlain,
} from "./../../../helpers/bec";

import {
    calculateTotalCoins,
    calculateAvgPrice,
} from "./../../../helpers/becCalculators";

export default {
    components: { datetime },

    data() {
        return {
            from: "",
            to: "",
            realizedPnlQuote: 0,

            realizedPnl: 0,
            realizedPnlPercentage: 0,

            unrealizedPnl: 0,
            unrealizedPnlPercentage: 0,

            netPnl: 0,
            netPnlPercentage: 0,

            totalPaidWithFees: 0,
            feesPaid: 0,
            feesPaidPercentage: 0,

            totalBuyCost: 0,
            totalSellCost: 0,

            avgBuyPrice: 0,

            amountOfTrades: 0,
            amountOfBuyTrades: 0,
            amountOfSellTrades: 0,
            breakEvenPrice: this.$store.state.internalApi.myTrades.length || 0,
        };
    },

    computed: {
        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        priceScale() {
            return this.$store.state.priceScale;
        },

        informationLoaded() {
            return this.$store.state.informationLoaded;
        },

        remainingBaseWorthInQuote() {
            return (
                this.$store.state.internalApi.balance[this.base].total *
                this.$store.state.internalApi.ticker.current
            );
        },

        tvWidget() {
            return this.$store.state.chart;
        },
    },

    watch: {
        from() {
            this.calculateRealizedPnlPercentage();
        },

        to() {
            this.calculateRealizedPnlPercentage();
        },

        tvWidget() {
            this.initializeTvWidget();
        },

        // This watcher is only ran when the api fetch has been completed
        informationLoaded() {
            if (this.informationLoaded === true) {
                this.calculateRealizedPnlPercentage();
            }
        },
    },

    mounted() {
        this.initializeTvWidget();
    },

    methods: {
        initializeTvWidget() {
            const self = this;

            this.tvWidget.onChartReady(() => {
                this.tvWidget.onContextMenu(function (unixtime, price) {
                    return [
                        {
                            position: "top",
                            text: "Set break even start",
                            click: () => { self.setBreakEvenTime('from', unixtime); }
                        },
                        {
                            position: "top",
                            text: "Set break even end",
                            click: () => { self.setBreakEvenTime('to', unixtime); }
                        },
                        { text: "-", position: "top" }
                    ]
                });
            });
        },

        calculateRealizedPnlPercentage() {
            const from = convertToEpoch(this.from);
            const to = convertToEpoch(this.to);
            console.groupCollapsed('Timestamps');
            console.log("From:", from);
            console.log("To:", to);
            console.groupEnd();

            // Get trades for each side "buy" & "sell"
            const sideBuyTrades = this.$store.state.internalApi.myTrades.filter(
                (trade) => {
                    return getCorrectTrades(trade, "buy", from, to);
                }
            );

            const sideSellTrades =
                this.$store.state.internalApi.myTrades.filter((trade) => {
                    return getCorrectTrades(trade, "sell", from, to);
                });

            const allTrades = [...sideBuyTrades, ...sideSellTrades];

            console.groupCollapsed('Trades');
            console.log("Side buy trades:");
            console.log(sideBuyTrades);
            console.log("Side sell trades:");
            console.log(sideSellTrades);
            console.groupEnd();

            // Calculate amount of trades in period
            this.amountOfTrades = sideBuyTrades.length + sideSellTrades.length;
            this.amountOfBuyTrades = sideBuyTrades.length;
            this.amountOfSellTrades = sideSellTrades.length;

            // Total Cost
            this.totalBuyCost = addUpBuyCost(sideBuyTrades);
            this.totalSellCost = addUpSellCost(sideSellTrades);
            console.groupCollapsed('Calculations');
            console.log("Total buy cost:", this.totalBuyCost);
            console.log("Total sell cost:", this.totalSellCost);

            // Total Coins
            const totalCoins = calculateTotalCoins(allTrades);
            const totalCoinsBoughtBuySide = calculateTotalCoins(sideBuyTrades);
            const totalCoinsSellSide = calculateTotalCoins(sideSellTrades);
            console.log("Total coins:", totalCoins);
            console.log("Total coins bought buyside:", totalCoinsBoughtBuySide);
            console.log("Total coins sold sellside:", totalCoinsSellSide);

            // Average Buy Price
            this.avgBuyPrice = calculateAvgPrice(
                this.totalBuyCost,
                totalCoinsBoughtBuySide
            );
            console.log("Average buy price:", this.avgBuyPrice.toFixed(10));

            // Average Sell Price
            const avgSellPrice = calculateAvgPrice(
                this.totalSellCost,
                totalCoinsSellSide
            );
            console.log("Average sell price:", avgSellPrice.toFixed(10));

            // Realized PNL
            this.realizedPnl =
                totalCoinsSellSide * (avgSellPrice - this.avgBuyPrice);
            this.realizedPnlPercentage = percentage.calculate(
                this.realizedPnl,
                this.totalBuyCost
            );
            console.log("Realized PNL:", this.realizedPnl);
            console.log("Realized PNL %:", this.realizedPnlPercentage);

            // Unrealized PNL
            const totalCoinsLeft = totalCoinsBoughtBuySide - totalCoinsSellSide;
            console.log("Total coins left:", totalCoinsLeft);
            this.totalLeftCost =
                totalCoinsLeft * this.$store.state.internalApi.ticker.current;
            console.log("Total left cost:", this.totalLeftCost);
            this.unrealizedPnl =
                totalCoinsLeft *
                (this.$store.state.internalApi.ticker.current -
                    this.avgBuyPrice);
            this.unrealizedPnlPercentage = percentage.calculate(
                this.unrealizedPnl,
                this.totalBuyCost
            );
            console.log("Unrealized PNL:", this.unrealizedPnl);
            console.log("Unrealized PNL %:", this.realizedPnlPercentage);

            // Fees
            this.feesPaid = addUpFeesCostPlain(allTrades);
            this.feesPaidPercentage = percentage.calculate(
                this.feesPaid,
                this.totalBuyCost
            );
            console.log("Total fees paid:", this.feesPaid);
            console.log("Total fees paid %:", this.feesPaidPercentage);

            // Net PNL
            this.netPnl = this.realizedPnl + this.unrealizedPnl;
            this.netPnl = this.netPnl - this.feesPaid;
            this.netPnlPercentage = percentage.calculate(
                this.netPnl,
                this.totalBuyCost
            );
            console.log("Net PNL:", this.netPnl);
            console.log("Net PNL %:", this.netPnlPercentage);

            // Calculate new break even price
            this.calculateNewBEPrice();
            console.groupEnd();
        },

        calculateNewBEPrice() {
            // Remove current break even point
            this.removeCurrentBreakEvenPoint();

            // If needed, draw a new break even price line
            this.drawBreakEvenPriceLine();

            this.breakEvenPrice = this.avgBuyPrice;
        },

        removeCurrentBreakEvenPoint() {
            if (this.$store.state.chartDrawings.breakEvenPointLine) {
                console.log(
                    "Removing current break even point",
                    this.$store.state.breakEvenPointLine
                );
                this.$store.state.chart
                    .activeChart()
                    .removeEntity(
                        this.$store.state.chartDrawings.breakEvenPointLine
                    );
            }
        },

        drawBreakEvenPriceLine() {
            setTimeout(() => {
                this.$store.state.chart.activeChart().dataReady(() => {
                    let breakEvenPointLineId = this.$store.state.chart
                        .activeChart()
                        .createMultipointShape(
                            [
                                {
                                    price: this.avgBuyPrice,
                                },
                            ],
                            {
                                shape: "horizontal_line",
                                lock: true,
                                disableSelection: true,
                                overrides: {
                                    linecolor: "#9F2B68",
                                },
                            }
                        );

                    this.$store.dispatch(
                        "changeBreakEvenPointLineAction",
                        breakEvenPointLineId
                    );
                });
                // console.log("Drawing a new break even point");
            }, 2000);
        },

        formatPercentageCss(percentage) {
            return Math.sign(parseFloat(percentage)) === 1
                ? "tw-text-green-600"
                : "tw-text-red-500";
        },

        checkPercentageToHigh(percentage) {
            if (parseFloat(percentage) > 1) {
                return "blink";
            }
        },

        setBreakEvenTime(whichOne, time) {
            this[whichOne] = moment(time * 1000).format("DD/MM/YYYY H:mm:ss");
        },
    },
};
