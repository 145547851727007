//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {};
    },

    computed: {
        fees() {
            return this.$store.state.options.fees;
        },
    },
};
