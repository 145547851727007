//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {};
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        userId() {
            return this.$store.state.internalApi.userId;
        },

        selectedTab: {
            get() {
                let url = window.location.href.split("/")[3];

                if (url == "") {
                    url = "/";
                }

                return url;
            },
            set(newValue) {
                return newValue;
            },
        },
    },

    mounted() {},

    methods: {
        navigateTo(url) {
            window.location.href = url;
        },

        logout() {
            document.getElementById("logout").submit();
        },
    },
};
