//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            amounts: [6, 10, 15, 20, 30, 40],
        };
    },

    methods: {
        setSpendAmount(value) {
            this.$emit("clicked", value);
        },
    },
};
