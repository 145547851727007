//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    formatCurrencyQuotePrice,
    formatCurrencyBasePrice,
} from "./../../../helpers/currencyFormatter";

export default {
    data() {
        return {};
    },

    computed: {
        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },

        base() {
            return this.$store.state.currentSymbolID.split("/")[0];
        },

        quote() {
            return this.$store.state.currentSymbolID.split("/")[1];
        },

        fetchingFunds() {
            return this.$store.state.fetchingFreeFundsInTradingMarkets.value;
        },

        quoteAvailable() {
            return (
                this.isZero(this.quote) ||
                formatCurrencyQuotePrice(
                    this.$store.state.freeFunds[this.quote].free
                )
            );
        },

        quoteReserved() {
            return (
                this.isZero(this.quote) ||
                formatCurrencyQuotePrice(
                    this.$store.state.freeFunds[this.quote].used
                )
            );
        },

        quoteTotal() {
            return (
                this.isZero(this.quote) ||
                formatCurrencyQuotePrice(
                    this.$store.state.freeFunds[this.quote].total
                )
            );
        },

        quoteEstimated() {
            let estimateMultiplier = 1;
            if (this.quote == "BTC") {
                estimateMultiplier = parseFloat(
                    this.$store.state.externalApi.priceBtc
                );
            }

            if (this.quote == "ETH") {
                estimateMultiplier = parseFloat(
                    this.$store.state.externalApi.priceEth
                );
            }

            return (
                this.isZero(this.quote) ||
                formatCurrencyQuotePrice(
                    this.$store.state.freeFunds[this.quote].total,
                    estimateMultiplier
                )
            );
        },

        baseAvailable() {
            return (
                this.isZero(this.base) ||
                formatCurrencyBasePrice(
                    this.$store.state.freeFunds[this.base].free
                )
            );
        },

        baseReserved() {
            return (
                this.isZero(this.base) ||
                formatCurrencyBasePrice(
                    this.$store.state.freeFunds[this.base].used
                )
            );
        },

        baseTotal() {
            return (
                this.isZero(this.base) ||
                formatCurrencyBasePrice(
                    this.$store.state.freeFunds[this.base].total
                )
            );
        },

        baseEstimated() {
            return (
                this.isZero(this.base) ||
                formatCurrencyBasePrice(
                    this.$store.state.freeFunds[this.base].total *
                    this.$store.state.internalApi.ticker.current
                )
            );
        },

        tradingMarkets() {
            let balanceArray = Object.entries(
                this.$store.state.internalApi.balance
            ).map((e) => ({ [e[0]]: e[1] }));

            if (this.liveMarket == "lbank") {
                return []; // Todo
            }

            return balanceArray.filter((market) => {
                return Object.values(market)[0].total > 0;
            });
        },

        allUsedMarkets() {
            let allArray = Object.entries(
                this.$store.state.internalApi.balance
            ).map((e) => ({
                [e[0]]: e[1],
            }));

            // Only return all caps, rest aren't real coins
            return allArray.filter((market) => {
                return isUpperCase(Object.keys(market)[0]);
            });
        },
    },

    methods: {
        isZero(pair) {
            if (this.$store.state.freeFunds[pair] === undefined) {
                return "0.00";
            }
        },
    },
};

function isUpperCase(str) {
    return str === str.toUpperCase();
}
