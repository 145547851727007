export const objectNotEmpty = (obj) => {
    return Object.keys(obj).length !== 0;
};

export const getQuoteObject = (balance, quote) => {
    if (!balance.hasOwnProperty(quote)) return;

    return Object.fromEntries(
        Object.entries(
            Object.entries(balance).filter(([key]) => key.includes(quote))
        )
    );
};

export const findTotalCoinLeftByKey = (object, key) => {
    if (!object.hasOwnProperty(key)) return;

    return Object.fromEntries(
        Object.entries(
            Object.entries(object).filter(([nativeKey]) =>
                nativeKey.includes(key)
            )
        )
    );
};

export const getCurrentCoinDataFromObject = (balance, currentSymbolTitle) => {
    return Object.keys(balance)
        .filter((key) => key.includes(currentSymbolTitle.split("_")[0]))
        .reduce((cur, key) => {
            return Object.assign(cur, {
                [key]: balance[key],
            });
        }, {});
};

export const getCurrentQuoteDataFromObject = (balance, currentSymbolTitle) => {
    return Object.keys(balance)
        .filter((key) => key.includes(currentSymbolTitle.split("_")[1]))
        .reduce((cur, key) => {
            return Object.assign(cur, {
                [key]: balance[key],
            });
        }, {});
};

export const addUpTotalForSpecificSide = (trades, side, currentPrice) => {
    const sideTrades = trades.filter((trade) => {
        return trade.side == side;
    });

    return (
        sideTrades.reduce((acc, trade) => acc + trade.amount, 0) * currentPrice
    );
};
