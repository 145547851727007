var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 tw-w-full",attrs:{"headers":_vm.headers,"items":_vm.marketLines,"items-per-page":20,"dark":"","sort-by":['netProfit'],"sort-desc":[false, true]},scopedSlots:_vm._u([{key:"item.netProfit",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.formatNetProfitColor(item.netProfit),domProps:{"textContent":_vm._s(item.netProfit)}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.currentQuote)}})]}},{key:"item.feesPaid",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.formatFeesPaidColor(item.feesPaid),domProps:{"textContent":_vm._s(item.feesPaid)}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.currentQuote)}})]}},{key:"item.invested",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.invested)}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.currentQuote)}})]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.formatNetProfitColor(item.roi),domProps:{"textContent":_vm._s(item.roi)}}),_vm._v("\n        %\n    ")]}}],null,true)},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"body.append"},[_c('tr',[_c('th'),_vm._v(" "),_c('th',{staticClass:"text-right tw-text-yellow-400"},[_vm._v("Total Profit")]),_vm._v(" "),_c('th',{staticClass:"text-right tw-text-yellow-400"},[_vm._v("Total Fees Paid")]),_vm._v(" "),_c('th',{staticClass:"text-right tw-text-yellow-400"},[_vm._v("Total Invested")]),_vm._v(" "),_c('th',{staticClass:"text-right tw-text-yellow-400"},[_vm._v("Average Total ROI")])]),_vm._v(" "),_c('tr',[_c('th'),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalNetProfit)+" USDT")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalFeesPaidTotal)+" USDT")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalInvested)+" USDT")]),_vm._v(" "),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalRoi)+" %")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }