//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sellRules } from "../../../../helpers/rules";

export default {
    props: [
        "ladderSellSellQuantity",
        "ladderSellStartPrice",
        "ladderSellEndPrice",
        "ladderSellAmountOfOrders",
    ],

    data() {
        return {
            items: 2,
        };
    },

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        rulesLadderSell() {
            return sellRules(
                this.ladderSellSellQuantity,
                this.$store.state.freefundsInQuote
            );
        },
    },
};
