export const internalApi = {
    state: () => ({
        liveMarket: localStorage.getItem("liveMarket") || "bitmart",
        myTrades: [],
        openOrders: [],
        closedOrders: [],
        orderBook: [],
        recentTrades: [],
        markets: [],
        tradingMarkets: [],
        allOpenOrders: [],
        allClosedOrders: [],
        balance: [],
        ticker: {
            current: 0,
            bid: 0,
            ask: 0,
        },
        twentyFourHourVol: 0,
        pairs: [],
        userId: null,
    }),

    mutations: {
        changeLiveMarket(state, payload) {
            state.liveMarket = payload;
        },

        changeMyTrades(state, payload) {
            state.myTrades = payload;
        },

        changeOpenOrders(state, payload) {
            state.openOrders = payload;
        },

        changeBreakEvenPrice(state, payload) {
            state.breakEvenPrice = payload;
        },

        changeOrderBook(state, payload) {
            state.orderBook = payload;
        },

        changeTickerCurrent(state, payload) {
            state.ticker.current = payload;
        },

        changeTickerBid(state, payload) {
            state.ticker.bid = payload;
        },

        changeTickerAsk(state, payload) {
            state.ticker.ask = payload;
        },

        changeRecentTrades(state, payload) {
            state.recentTrades = payload;
        },

        changeMarkets(state, payload) {
            state.markets = payload;
        },

        changeTradingMarkets(state, payload) {
            state.tradingMarkets = payload;
        },

        changeAllOpenOrders(state, payload) {
            state.allOpenOrders = payload;
        },

        changeAllClosedOrders(state, payload) {
            state.allClosedOrders = payload;
        },

        changeBalance(state, payload) {
            state.balance = payload;
        },

        change24hVol(state, payload) {
            state.twentyFourHourVol = payload;
        },

        changePairs(state, payload) {
            state.pairs = payload;
        },

        changeUserId(state, payload) {
            state.userId = payload;
        },
    },

    actions: {
        changeLiveMarketAction({ commit }, liveMarket) {
            commit("changeLiveMarket", liveMarket);
        },

        changeMyTradesAction({ commit }, myTrades) {
            commit("changeMyTrades", myTrades);
        },

        changeOpenOrdersAction({ commit }, openOrders) {
            commit("changeOpenOrders", openOrders);
        },

        changeOrderBookAction({ commit }, orderBook) {
            commit("changeOrderBook", orderBook);
        },

        changeTickerCurrentAction({ commit }, tickerCurrent) {
            commit("changeTickerCurrent", tickerCurrent);
        },

        changeTickerBidAction({ commit }, tickerBid) {
            commit("changeTickerBid", tickerBid);
        },

        changeTickerAskAction({ commit }, tickerAsk) {
            commit("changeTickerAsk", tickerAsk);
        },

        changeRecentTradesAction({ commit }, recentTrades) {
            commit("changeRecentTrades", recentTrades);
        },

        changeMarketsAction({ commit }, markets) {
            commit("changeMarkets", markets);
        },

        changeTradingMarketsAction({ commit }, tradingMarkets) {
            commit("changeTradingMarkets", tradingMarkets);
        },

        changeAllOpenOrdersAction({ commit }, allOpenOrders) {
            commit("changeAllOpenOrders", allOpenOrders);
        },

        changeAllClosedOrdersAction({ commit }, allClosedOrders) {
            commit("changeAllClosedOrders", allClosedOrders);
        },

        changeBalanceAction({ commit }, markets) {
            commit("changeBalance", markets);
        },

        change24hVolAction({ commit }, twentyFourHourVol) {
            commit("change24hVol", twentyFourHourVol);
        },

        changePairsAction({ commit }, pairs) {
            commit("changePairs", pairs);
        },

        changeUserIdAction({ commit }, id) {
            commit("changeUserId", id);
        },
    },
};
