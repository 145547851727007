//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {};
    },

    computed: {
        currentSymbol() {
            return this.$store.state.currentSymbolTitle;
        },

        priceBtc() {
            return this.$store.state.externalApi.priceBtc;
        },

        priceEth() {
            return this.$store.state.externalApi.priceEth;
        },

        liveMarket() {
            return this.$store.state.internalApi.liveMarket;
        },
    },

    methods: {
        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },

        fetchMarkets() {
            fetch(`${this.liveMarket}/fetchMarketsFirst`)
                .then((response) => response.json())
                .then((data) => {
                    this.$vToastify.success(data);
                    window.location.reload();
                });
        },
    },
};
