//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { buyRules } from "../../../../helpers/rules";

export default {
    props: ["buyQuantity"],

    computed: {
        base() {
            return this.$store.state.currentSymbolTitle.split("_")[0];
        },

        quote() {
            return this.$store.state.currentSymbolTitle.split("_")[1];
        },

        rulesMarketBuy() {
            return buyRules(
                this.buyQuantity,
                this.$store.state.freefundsInQuote
            );
        },
    },
};
